import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as Constant from "../Constant";
import OwlCarousel from "react-owl-carousel";

class ListVertical extends Component {
  constructor() {
    super();
  }

  numberFormat(value) {
    return value
      .toLocaleString(navigator.language, { minimumFractionDigits: 0 })
      .replace(/\,/g, ".");
  }

  dateDiff(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    return Math.floor(
      (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
  }

  render() {
    let title = "Undefined";
    if (this.props.title) {
      title = this.props.title;
    }

    let data = null;
    if (this.props.data) {
      data = this.props.data;
    }

    let link = "/";
    if (this.props.link) {
      link = this.props.link;
    }

    return (
      <div className="content-boxed">
        <div className="content bottom-10">
          <h5 className="float-left font-600">{title}</h5>
          <div className="clear"></div>
        </div>
        {data !== null ? (
          data.map((val, i) => {
            let image_link = "";
            let percent = 0;
            let today = Date.now();
            let final_day = new Date(val.tanggal);
            let day_remaining = this.dateDiff(today, final_day);
            if (link === "update") {
              image_link = Constant.GET_UPDATES_IMAGE + val.id_posting;
            }
            if (link === "programs") {
              image_link = Constant.GET_PROGRAMS_IMAGE + val.id;
              percent = (val.total / val.dana_target) * 100;
              if (percent > 100) {
                percent = 100;
              }
              if (!val.tanggal) {
                final_day = null;
                day_remaining = "∞";
              }
            }
            return (
              <>
                {link === "update" ? (
                  <Link to={"/update/" + val.seo} key={i}>
                    <div
                      className="content-boxed"
                      style={{ paddingBottom: "0px" }}
                    >
                      <div className="content" style={{ display: "flex" }}>
                        <img
                          className="update-list-image"
                          style={{ borderRadius: "10px" }}
                          src={image_link}
                        />
                        <div className="update-list-desc">
                          <h6 className="update-list-title">{val.judul}</h6>
                          <i>
                            <label
                              className="category-program"
                              style={{ fontWeight: "bold" }}
                            >
                              {val.category}
                            </label>
                          </i>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <Link
                    to={"/detailprogram/" + val.seo}
                    className="red-text padding-left-right"
                    key={i}
                  >
                    <div className="content" id="program-list-content">
                      <img
                        className="program-home-image"
                        style={{ borderRadius: "5px" }}
                        src={image_link}
                      />
                      <div className="program-desc">
                        <b>
                          <p
                            className="title-program"
                            style={{ fontSize: "12px" }}
                          >
                            {/* {String(val.judul).substring(0, 30)}... */}
                            {val.judul}
                          </p>
                        </b>
                        <div className="category-program">
                          <i style={{ fontWeight: "bold" }}>{val.category}</i>
                        </div>
                        <div className="w3-light-grey">
                          <div
                            className="w3-red"
                            style={{ height: 5 + "px", width: `${percent}%` }}
                          ></div>
                        </div>
                        <div className="dana" style={{ marginTop: `10px` }}>
                          {day_remaining < 0 ? (
                            <div className="penggalangan-terlewat">
                              Penggalangan dana sudah berakhir <br />
                              <div className="dana-terkumpul">
                                Terkumpul <br />{" "}
                                <b>
                                  {" "}
                                  <p>Rp. {this.numberFormat(val.total)}</p>{" "}
                                </b>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="dana-terkumpul">
                                Terkumpul <br />
                                <b>
                                  <p>Rp. {this.numberFormat(val.total)}</p>
                                </b>
                              </div>

                              <div className="sisa-hari">
                                Sisa Hari <br />
                                <b>
                                  <p>{day_remaining}</p>
                                </b>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr />
                  </Link>
                )}
              </>
            );
          })
        ) : (
          <center>
            <label>Tidak ada data.</label>
          </center>
        )}
        <div>
          <Link
            to={link}
            className="category-program"
            style={{ float: "right", fontWeight: "bold" }}
          >
            Lihat Lainnya...
          </Link>
        </div>
      </div>
    );
  }
}

export default ListVertical;
