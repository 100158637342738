import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import * as Constant from "../Constant";
import axios from "axios";
// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import Cookies from "universal-cookie";
// -----
import Footer from "../Components/Footer";
import Header from "../Components/Header";
//
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Modal from "react-modal";
// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #ea6123;
`;

const customStyles = {
  content: {
    top: "10px",
    bottom: "10px",
    right: "0px",
    width: "260px",
    overflow: "scroll",
    border: "0px",
    borderRadius: "15px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    zIndex: 100,
  },
  overlay: {
    zIndex: 99,
    backgroundColor: "rgba(0, 0, 0, 0.45)",
  },
};

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader type="ThreeDots" color={"#ea6123"} height="60" width="60" />
      </div>
    )
  );
};

Modal.setAppElement("#root");
const cookies = new Cookies();
class Program extends Component {
  constructor() {
    super();
    this.state = {
      categories: [],
      programs: [],
      page: 1,
      last_page: 1,
      search: null,
      category_id: null,
      category_id_before: null,
      go_home: false,
      go_detail: false,
      seo_key: "",
      loading: true,

      go_donate: false,
      categoryf: "",
      isAvailable: "",

      modalIsOpen: false,
      setIsOpen: false,
    };

    this.timer = null;
    this.trackScrolling = this.trackScrolling.bind(this);
  }

  componentDidMount() {
    this.getId();
    if (cookies.get("id_yayasan") != null) {
      this.getCategories();
      this.checkCategoryFromProps();
    }
    document.addEventListener("scroll", this.trackScrolling);

    // Modal Filter Close
    window.onclick = function (event) {
      if (event.target == document.getElementById("menu-main")) {
        document.getElementById("menu-hider");
      }
    };

    if (this.props.location.search != null) {
      this.setValueFromQueryUrl();
    }
  }

  getId() {
    if (cookies.get("id_yayasan") == null) {
      axios.get(Constant.GET_YAYASAN, {
        headers: {
          'X-API-KEY': Constant.X_API_KEY
        }
      }).then((res) => {
        var idc = res.data.data.id;
        cookies.set("id_yayasan", idc);
        this.getCategories();
        this.checkCategoryFromProps();
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }
  trackScrolling() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.getElementById("programs_scroller");
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight && this.state.page != this.state.last_page) {
      // console.log(windowBottom + " == " + docHeight)
      // reach to bottom
      let next = this.state.page + 1;
      this.setState({
        page: next,
        loading: true,
      });
      this.getPrograms();
      // console.log('there it is')
    }
  }
  handleInputChange(e) {
    clearTimeout(this.timer);

    const { name, value } = e.target;
    this.setState({ [name]: value, page: 1 });

    this.timer = setTimeout(() => this.getPrograms(), 1000);
  }
  handleKeyDown(e) {
    if (e.keyCode === 13) {
      clearTimeout(this.timer);

      this.getPrograms();
    }
  }
  categorySelected(category_id, categoryf) {
    this.setState({
      category_id,
      page: 1,
      categoryf,
      programs: [],
      setIsOpen: false,
    });

    this.timer = setTimeout(() => this.getPrograms(), 250);
  }
  setValueFromQueryUrl() {
    const { page, search, category_id } = this.state;

    let url = this.props.location.search;
    let split_url = String(url).substring(1).split("&");

    // console.log(split_url)
    split_url.map((query) => {
    //   console.log(query);
      let pisah = query.split("=");
      if (pisah[0] == "page") {
        this.setState({ page: pisah[1] });
      } else if (pisah[0] == "search_query_first") {
        this.setState({ search: pisah[1] });
      } else if (pisah[0] == "search_query_last") {
        this.setState({ category_id: pisah[1] });
      }
    });
  }

  getCategories() {
    // get categories
    axios
      .get(Constant.GET_CATEGORIES + "?id_yayasan=" + cookies.get("id_yayasan"))
      .then((response) => {
        // console.log(response.data)
        this.setState({ categories: response.data.data });
      })
      // Catch any error here
      .catch((error) => {
        console.log(error);
      });
  }
  getPrograms() {
    const { page, search, category_id, category_id_before } = this.state;

    let halaman = 1;
    if (category_id != category_id_before) {
      this.setState({
        page: 1,
      });
      halaman = 1;
    } else {
      halaman = page;
    }

    let query_url = `?page=${halaman}`;
    query_url += `&id_yayasan=${cookies.get("id_yayasan")}`;
    if (search) query_url += `&keyword=${search}`;
    if (category_id) query_url += `&idKategori=${category_id}`;

    this.setState({
      category_id_before: category_id,
      isAvailable: "",
    });

    trackPromise(
      axios
        .get(Constant.GET_PROGRAMS + query_url)
        .then((response) => {
          // console.log(response.data)
          if (page == 1) {
            this.setState({
              programs: response.data.data,
              last_page: response.data.last_page,
              loading: false,
            });
          } else {
            if (category_id != category_id_before) {
              this.setState({
                programs: response.data.data,
                last_page: response.data.last_page,
                page: 1,
                loading: false,
              });
            //   console.log(response.data.data);
            } else {
              let newPrograms = this.state.programs.concat(response.data.data);
              this.setState({
                programs: newPrograms,
                last_page: response.data.last_page,
                loading: false,
              });
            }
          }

          if (response.data.data.length == 0) {
            this.setState({ isAvailable: "Tidak ada data" });
          }
        })
        // Catch any error here
        .catch((error) => {
          console.log(error);
        })
    );
  }

  checkCategoryFromProps() {
    let props_category = this.props.location.state
      ? this.props.location.state.category_id
      : null;

    if (props_category) this.setState({ category_id: props_category });

    this.timer = setTimeout(() => this.getPrograms(), 250);
  }
  numberFormat(value) {
    return value
      .toLocaleString(navigator.language, { minimumFractionDigits: 0 })
      .replace(/\,/g, ".");
  }
  dateDiff(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    return Math.floor(
      (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
  }
  dateFormat(date) {
    if (date) {
      let days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jum`at",
        "Sabtu",
      ];
      let months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      let new_date = new Date(date);
      let formatted_date =
        days[new_date.getDay()] +
        ", " +
        new_date.getDate() +
        " " +
        months[new_date.getMonth()] +
        " " +
        new_date.getFullYear();

      return formatted_date;
    } else {
      return "Tanpa Batas Waktu";
    }
  }

  render() {
    const { match } = this.props;
    const {
      categories,
      search,
      category_id,
      go_home,
      go_detail,
      seo_key,
      go_donate,
      categoryf,
      isAvailable,
      modalIsOpen,
      setIsOpen,
    } = this.state;

    if (this.props.match.url === "/prog") window.location.href = "/programs";

    if (go_home) return <Redirect exact to={"/"} />;
    else if (go_detail)
      return <Redirect exact to={`/detailprogram/${seo_key}`} />;
    else if (go_donate) return <Redirect exact to={`/donateform/${seo_key}`} />;

    return (
      <div>
        <div>
          <div className="header header-fixed header-logo-app header-auto-show">
            <div className="res">
              <i
                id="ic-sidebar"
                className="fa fa-arrow-left"
                onClick={() => this.setState({ go_home: true })}
                style={{ padding: 20 + "px" }}
              ></i>
              Daftar Program
            </div>
          </div>
          <Header />

          <div className="content">
            <div style={{ display: "flex" }}>
              <div className="search-box search-color shadow-tiny round-huge bottom-0 bg-in">
                <i className="fa fa-search"></i>
                <input
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Cari Program ... "
                  value={search ? search : ""}
                  onChange={(e) => this.handleInputChange(e)}
                  onKeyDown={(e) => this.handleKeyDown(e)}
                  data-search
                />
              </div>
              {/* <div className="set-icon" style={{ color: '#fff' }}>
                                <a href="#" data-menu="menu-main" className="scale-box" onClick={() => document.getElementById("menu-main").style.display = "block"}>
                                    <i className="fa fa-filter fa-lg"></i>
                                </a>
        </div> */}
              <div className="set-icon" style={{ color: "#fff" }}>
                <div className="scale-box">
                  <i
                    className="fa fa-filter fa-lg"
                    onClick={() => this.setState({ setIsOpen: true })}
                    style={{ cursor: "pointer" }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <ClipLoader
            css={override}
            size={150}
            color={"red"}
            loading={this.state.loading}
          />
          <div className="page-content">
            <div className="scroller-program" id="programs_scroller">
              <div className="content-boxed">
                <center>
                  <label
                    style={{
                      fontWeight: "bold",
                      fontSize: "22px",
                      color: "#ea6123",
                    }}
                  >
                    Daftar Program
                  </label>
                  <p
                    style={{
                      color: "#ea6123",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {categoryf == "" || categoryf == null
                      ? "Kategori: Semua"
                      : "Kategori: " + categoryf}
                  </p>
                  <LoadingIndicator />
                </center>
              </div>
              {this.state.programs.length > 0 ? (
                this.state.programs.map((program, i) => {
                  let image_link =
                    Constant.GET_PROGRAMS_IMAGE + program.id_program;

                  let total = 0;
                  let percent = 0;

                  program.program.donatur.map((donatur, j) => {
                    total = total + donatur.total;
                  });

                  percent = (total / program.program.dana_target) * 100;
                  if (percent > 100) {
                    percent = 100;
                  }

                  let today = Date.now();
                  let final_day = new Date(program.program.tanggal);
                  let day_remaining = this.dateDiff(today, final_day);

                  if (!program.program.tanggal) {
                    final_day = null;
                    day_remaining = "∞";
                  }

                  return (
                    <Link to={"/detailprogram/" + program.seo} key={i}>
                      <div className="content-boxed">
                        <div className="content" id="program-list-content" style={{ height: "auto" }}>
                          <img
                            className="program-home-image"
                            src={image_link}
                          />
                          <div className="program-desc">
                            <h6 className="title-program">
                              {/* {String(program.judul).substring(0, 20)}... */}
                              {program.judul}
                            </h6>
                            <i>
                              <p className="category-program">
                                {program.program.kategori.category}
                              </p>
                            </i>
                            <div className="w3-light-grey">
                              <div
                                className="w3-red"
                                style={{
                                  height: 5 + "px",
                                  width: `${percent}%`,
                                }}
                              ></div>
                            </div>
                            <div
                              className="dana"
                              style={{ marginTop: 10 + "px" }}
                            >
                              {day_remaining < 0 ? (
                                <div className="penggalangan-terlewat">
                                  Penggalangan dana sudah berakhir <br/>
                                  <div className="dana-terkumpul">
                                    Terkumpul <br />{" "}
                                    <b>
                                      {" "}
                                      <p>
                                        Rp. {this.numberFormat(total)}
                                      </p>{" "}
                                    </b>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div className="dana-terkumpul">
                                    Terkumpul <br />
                                    <b>
                                      <p>Rp. {this.numberFormat(total)}</p>
                                    </b>
                                  </div>

                                  <div className="sisa-hari">
                                    Sisa Hari <br />
                                    <b>
                                      <p>{day_remaining}</p>
                                    </b>
                                  </div>
                                </>
                              )}
                            </div>
                            <p>{String(program.resume).substring(0, 50)}...</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })
              ) : (
                <div className="content-boxed">
                  <center>
                    <p
                      style={{
                        color: "#ea6123",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {isAvailable}
                    </p>
                  </center>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />

        <Modal
          isOpen={this.state.setIsOpen}
          onRequestClose={() => this.setState({ setIsOpen: false })}
          contentLabel="Example Modal"
          style={customStyles}
          className="menu menu-box-right round-medium res"
          closeTimeoutMS={300}
        >
          <div className="res">
            <div className="filter">
              <div>
                <h3 style={{ marginBottom: 30 + "px" }}>Kategori</h3>
                <div>
                  {categories.map((category, i) => {
                    return (
                      <div key={i} style={{ cursor: "pointer" }}>
                        <p
                          value={category.id}
                          onClick={() =>
                            this.categorySelected(
                              category.id,
                              category.category
                            )
                          }
                        >
                          {category.category}
                        </p>
                        <hr style={{ margin: 0 }} />
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    marginTop: 50 + "px",
                    bottom: 0 + "px",
                    cursor: "pointer",
                  }}
                >
                  <p
                    className="filter-btn"
                    onClick={() => this.categorySelected(null)}
                  >
                    Semua Kategori
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Program;
