import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import * as Constant from "../../Constant";
import axios from "axios";
import Cookies from "universal-cookie";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import PaymentMethod from "../../Xendit/PaymentMethod";
import Modal from "react-modal";

import {
  Nominal,
  Input,
  MetodePembayaran,
} from "../../Components/FormComponent";

import Header from "../../Components/Header";

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader type="ThreeDots" color={"white"} height="40" width="40" />
      </div>
    )
  );
};

const cookies = new Cookies();
Modal.setAppElement("#root");

const customStyles = {
  content: {
    position: "relative",
    top: "25px",
    bottom: "10px",
    right: "0px",
    width: "290px",
    border: "0px",
    borderRadius: "15px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    zIndex: 100,
  },
  overlay: {
    zIndex: 99,
    backgroundColor: "rgba(0, 0, 0, 0.45)",
  },
};

class Donate extends Component {
  constructor() {
    super();
    this.state = {
      user_id: null,
      program: {},
      donation: 0,
      full_name: "",
      phone_number: "",
      email: "",

      phone: "",
      card_number: "",
      exp_month: "",
      exp_year: "",
      cvn: "",
      alfamart_code: "ALFAMARTCODEHERE!!!",

      go_back: false,
      go_paid: false,

      go_payment: false,
      payment_method: "",
      checkout_url: "",
      go_via_checkout: false,

      donation_type: [],
      nominals: [],
      metode_pembayaran: "Pilih Metode Pembayaran",
      btn_donasi: "Donasi",
      setIsOpen: false,

      //PaymentMethod
      payment_method: null,
      vendor: null,
      selected_method: null,
      selected_vendor: null,
      no_rekening: "",

      // moota
      bank_moota: "-",
      norek_moota: "-",
      nominal_moota: 0,
    };
    this.sendTransaction = this.sendTransaction.bind(this);
  }

  componentDidMount() {
    this.getProgramDetail();
    this.setUserIfLogin();
    //this.getNominal()
    if (cookies.get("customerBio")) {
      this.setState({
        user_id: cookies.get("customerBio").user_id,
        full_name: cookies.get("customerBio").full_name,
        phone_number: cookies.get("customerBio").phone_number,
        email: cookies.get("customerBio").email,
      });
    }

    if (this.state.selected_method !== null) {
      this.getPaymentMethod();
    } else {
      document.getElementById("label_phone").hidden = true;
      document.getElementById("card_number").hidden = true;
      document.getElementById("exp_month").hidden = true;
      document.getElementById("exp_year").hidden = true;
      document.getElementById("cvn").hidden = true;
      this.setState({ metode_pembayaran: "Pilih Metode Pembayaran" });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected_method !== this.state.selected_method) {
      this.getPaymentMethod();
    }
  }

  getCustomerInfo() {
    if (this.state.selected_method !== null) {
      this.getPaymentMethod();
    } else {
      document.getElementById("label_phone").hidden = true;
      document.getElementById("card_number").hidden = true;
      document.getElementById("exp_month").hidden = true;
      document.getElementById("exp_year").hidden = true;
      document.getElementById("cvn").hidden = true;

      this.setState({ metode_pembayaran: "Pilih Metode Pembayaran" });
    }
  }

  getProgramDetail() {
    let {
      match: { params },
    } = this.props;
    axios
      .get(Constant.GET_PROGRAM_DETAIL + params.seoKey)
      .then((response) => {
        // console.log(response.data)
        this.setState({
          program: response.data.data,
          image_link:
            Constant.GET_PROGRAMS_IMAGE + response.data.data.id_program,
        });
        this.getDonationNominals();
      })
      // Catch any error here
      .catch((error) => {
        console.log(error);
      });
  }
  getDonationNominals() {
    const { program } = this.state;
    let id_program = program.id_program;
    axios
      .get(Constant.GET_DONATION_NOMINAL + id_program)
      .then((response) => {
        // console.log(response.data.data);
        if (response.data.data) {
          this.setState({
            donation_type: response.data.data,
          });
        } else {
          this.getNominal();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  setUserIfLogin() {
    if (cookies.get("user_id") != null) {
      axios
        .get(Constant.GET_USER, {
          headers: {
            Authorization: cookies.get("accessToken"),
          },
        })
        .then((response) => {
          this.setState({
            user_id: response.data.id,
            full_name: response.data.name,
            phone_number: response.data.no_telp,
            email: response.data.email,
          });

          document.getElementById("full_name").readOnly = true;
          // document.getElementById('phone_number').readOnly = true
          document.getElementById("email").readOnly = true;
          document.getElementById("btn_for_login").hidden = true;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  getNominal() {
    axios
      .get(Constant.GET_NOMINAL_BUTTON)
      .then((response) => {
        this.setState({ nominals: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getPaymentMethod() {
    let selected_method = cookies.get("selected_method");
    this.setState({ metode_pembayaran: "Ubah Metode Pembayaran" });
    if (selected_method != null || selected_method != "") {
      cookies.remove("linkBefore");

      // console.log(cookies.get('vendor').payment_name)
      if (this.state.vendor !== null) {
        if (
          this.state.vendor.payment_name == "OVO" ||
          this.state.vendor.payment_name == "LINKAJA"
        ) {
          //document.getElementById('label_phone').hidden = false
          document.getElementById("phone").hidden = false;
        } else {
          //document.getElementById('label_phone').hidden = true
          document.getElementById("phone").hidden = true;
        }

        if (this.state.vendor.payment_name == "Visa, Mastercard, JCB") {
          document.getElementById("card_number").hidden = false;
          document.getElementById("exp_month").hidden = false;
          document.getElementById("exp_year").hidden = false;
          document.getElementById("cvn").hidden = false;
        } else {
          document.getElementById("card_number").hidden = true;
          document.getElementById("exp_month").hidden = true;
          document.getElementById("exp_year").hidden = true;
          document.getElementById("cvn").hidden = true;
        }
      }
    }
  }
  onSuggestButtonClicked(value) {
    this.setState({ donation: parseInt(value.replace(/\./g, "")) });
  }
  onAmountChanged(e) {
    const { name, value } = e.target;
    // console.log(value)
    this.setState({
      [name]: parseInt(value.split(".").join("").split(",").join("")),
    });
  }
  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  doDonation() {
    const {
      user_id,
      program,
      donation,
      selected_vendor,
      payment_method,
      full_name,
      phone_number,
      email,
      phone,
      btn_donasi,
      donation_type,
    } = this.state;

    let url = this.props.location.pathname.split("/");
    let array = {
      donation: donation,
      user_id: user_id,
      full_name: full_name,
      phone_number: phone_number,
      email: email,
    };
    
    var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

    if (selected_vendor !== null) {
      if (selected_vendor.payment_type != "e_wallet") {
        if (donation_type !== null && donation < donation_type.value) {
          alert("Donasi Minimal Rp." + donation_type.value);
        } else if (!donation || donation < 10000) {
          alert("Donasi Untuk Metode Pembayaran Ini Minimal Rp.10.000");
        } else if (full_name == "" || full_name == null) {
          alert("Nama Lengkap Tidak Boleh Kosong");
        } else if (phone_number == "" || phone_number == null) {
          alert("Nomor Telepon Tidak Boleh Kosong");
        } else if (email == "" || email == null) {
          alert("Email Tidak Boleh Kosong");
        } else if (payment_method == 0) {
          alert("Pilih Metode Pembayaran");
        } else {
          if (pattern.test(email)) {
            let vendor_name = selected_vendor.payment_name;
            // console.log(vendor_name)
            if (vendor_name == "OVO") {
              if (phone == null || phone == "") {
                alert("Harap Isi Nomor Telepon E-Wallet Anda");
              } else {
                document.getElementById("ovo_popup").style.display = "block";
                this.sendTransaction();
              }
            } else {
              this.sendTransaction();
            }
          } else {
            alert("Email Tidak Valid !");
          }
        }
      } else {
        if (donation_type !== null && donation < donation_type.value) {
          alert("Donasi Minimal Rp." + donation_type.value);
        } else if (!donation || donation < 1) {
          alert("Donasi Minimal Rp.1");
        } else if (full_name == "" || full_name == null) {
          alert("Nama Lengkap Tidak Boleh Kosong");
        } else if (phone_number == "" || phone_number == null) {
          alert("Nomor Telepon Tidak Boleh Kosong");
        } else if (email == "" || email == null) {
          alert("Email Tidak Boleh Kosong");
        } else if (payment_method == 0) {
          alert("Pilih Metode Pembayaran");
        } else {
          if (pattern.test(email)) {
            let vendor_name = selected_vendor.payment_name;
            if (vendor_name == "OVO") {
              if (phone == null || phone == "") {
                alert("Harap Isi Nomor Telepon E-Wallet Anda");
              } else {
                document.getElementById("ovo_popup").style.display = "block";
                this.sendTransaction();
              }
            } else {
              this.sendTransaction();
            }
          } else {
            alert("Email Tidak Valid !");
          }
        }
      }
    } else {
      alert("Harap Pilih Metode Pembayaran");
    }
  }
  updateDeviceTypeTransaction(id) {
    let parameter = {
      id: id,
      transaksi_id: "P" + id,
      device: "PWA",
    };
    axios
      .post(Constant.UPDATE_DEVICE_AFTER_TRANSACTION, parameter, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response.data)
        this.setState({ go_paid: true });
      })
      // Catch any error here
      .catch((error) => {
        console.log(error);
      });
  }
  sendTransaction() {
    const {
      user_id,
      program,
      donation,
      selected_vendor,
      payment_method,
      phone,
      full_name,
      phone_number,
      email,
      card_number,
      exp_month,
      exp_year,
      cvn,
      no_rekening,
    } = this.state;

    let nomor_hp = null;
    if (
      selected_vendor.payment_name == "OVO" ||
      selected_vendor.payment_name == "DANA"
    ) {
      nomor_hp = phone;
    } else {
      nomor_hp = phone_number;
    }
    let metode_pembayaran = null;
    if (selected_vendor.payment_type == "virtual_account") {
      metode_pembayaran = selected_vendor.midtrans_code;
    } else if (selected_vendor.payment_name == "Alfamart") {
      metode_pembayaran = selected_vendor.payment_name.toUpperCase();
    } else {
      metode_pembayaran = selected_vendor.payment_name;
    }

    let array = [];
    if (selected_vendor.payment_type == "credit_card") {
      array = {
        account_number: card_number,
        exp_month: exp_month,
        exp_year: exp_year,
        cvn: cvn,
      };
    }

    const parameter = {
      key_yayasan: Constant.KEY_YAYASAN,
      nominal: donation,
      nama_lengkap: full_name,
      email: email,
      no_handphone: phone_number,
      program_id: program.id_program,
      vendor: selected_vendor.vendor,
      payment_type: selected_vendor.payment_type,
      payment_method: metode_pembayaran,
      no_rekening: no_rekening,
      device: "PWA",
      user_id: user_id,
    };
    this.setState({ btn_donasi: "" });
    cookies.remove("linkBefore");
    //cookies.remove('customerBio')
    trackPromise(
      axios.post(Constant.STORE_XENDIT, parameter).then((response) => {
        const res_data = response.data

        if (res_data.snap_token != null) {
          //console.log(response)
          window.snap.pay(res_data.snap_token, {
            // Optional
            onSuccess: function (result) {
              let pisah_url = window.location.href.split("/");
              window.location =
                pisah_url[0] + "//" + pisah_url[2] + "/notif/notif-wait";
            },
            // Optional
            onPending: function (result) {
              let pisah_url = window.location.href.split("/");
              window.location =
                pisah_url[0] + "//" + pisah_url[2] + "/notif/notif-wait";
            },
            // Optional
            onError: function (result) {
              console.log("gagal");
            },
            onClose: function (result) {
              console.log(res_data);
              let id = "";
              if (selected_vendor.vendor == "midtrans") {
                id = res_data.snap_token;
              }
              const data_send = {
                iDtoken: id,
                status: 1,
                key_yayasan: Constant.KEY_YAYASAN,
              };
              axios
                .post(Constant.DELETE_SNAPTOKEN, data_send)
                .then((response) => {
                  console.log("Transaksi Dibatalkan");
                })
                .catch((error) => {
                  console.log(error);
                });
            },
          });
          this.setState({ btn_donasi: "Donasi" });
        } else {
          if (res_data.data_ew) {
            this.setState({ btn_donasi: "Donasi" });
            window.location.href = res_data.data_ew.checkout_url;
          } else if (res_data.data_ro) {
            this.setState({
              btn_donasi: "Donasi",
              alfamart_code: res_data.data_ro.payment_code,
            });
            document.getElementById("alfamart_popup").style.display = "block";
          } else if (res_data.moota) {
            console.log(res_data)
            this.setState({
              btn_donasi: "Saya sudah transfer",
              bank_moota: res_data.moota.nama_bank,
              norek_moota: res_data.moota.no_rekening,
              nominal_moota: res_data.moota.nominal,
            });
          } else {
            let pisah_url = window.location.href.split("/");
            window.location =
              pisah_url[0] + "//" + pisah_url[2] + "/notif/notif-wait";
          }
        }
      })
    );
  }

  numberFormat(value) {
    return value
      .toLocaleString(navigator.language, { minimumFractionDigits: 0 })
      .replace(/\,/g, ".");
  }

  //PaymentMethod
  callbackVendor = (callVendor, callMethod, callRekening) => {
    this.setState({
      selected_method: callMethod,
      selected_vendor: callVendor,
      no_rekening: callRekening,
    });
  };

  callbackNominal = (nominal) => {
    this.setState({ donation: nominal });
  };

  callbackInput = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const {
      match,
      match: { params },
    } = this.props;
    const {
      program,
      image_link,
      donation,
      full_name,
      payment_method,
      vendor,
      phone,
      phone_number,
      email,
      go_back,
      go_paid,
      go_payment,
      donation_type,
      card_number,
      exp_month,
      exp_year,
      cvn,
      checkout_url,
      go_via_checkout,
      alfamart_code,
      nominals,
      metode_pembayaran,
      btn_donasi,
      selected_method,
      selected_vendor,
      bank_moota,
      norek_moota,
      nominal_moota,
    } = this.state;

    let foto = "";

    if (selected_vendor) {
      foto = Constant.GET_PICT_VENDOR + selected_vendor.id;
    }

    if (go_back)
      return <Redirect exact to={`/detailprogram/${params.seoKey}`} />;
    else if (go_paid) return <Redirect exact to="/notif/notif-wait" />;
    else if (go_payment) return <Redirect exact to="/payment_method" />;

    if (nominal_moota > 0)
      document.getElementById("moota_popup").style.display = "block";

    return (
      <div className="page-content">
        {/* <header id="header">
                <nav>
                    <i id="ic-sidebar" className="fa fa-arrow-left" onClick={() => this.setState({ go_back: true })}></i>
                </nav>
            </header> */}
        <Header back_url={"/programs/" + program.seo} />
        <div className="header header-fixed header-logo-app header-auto-show">
          <div className="res">
            <i
              id="ic-sidebar"
              className="fa fa-arrow-left"
              onClick={() => this.setState({ go_back: true })}
              style={{ padding: 20 + "px" }}
            ></i>
            {String(program.judul).substring(0, 32)}...
          </div>
        </div>

        <div
          className="content-boxed"
          style={{
            marginTop: 20 + "px",
            marginBottom: 10 + "px",
            paddingBottom: "10px",
          }}
        >
          <div className="content" id="program-list-content">
            <div
              className="program-home-image"
              style={{ backgroundImage: `url(${image_link})` }}
            ></div>
            <div className="program-desc">
              <p style={{ marginBottom: 0 }}>Program yang di pilih :</p>
              <b>
                <p className="title-program">{program.judul}</p>
              </b>
            </div>
          </div>
        </div>

        {/* Moota Popup */}
        <div id="moota_popup" className="modalfilter">
            <div className="filter-content">
                <span className="close" onClick={() => {
                    document.getElementById("moota_popup").style.display = "none"
                    this.setState({
                      btn_donasi: "Donasi",
                      bank_moota: "-",
                      norek_moota: "-",
                      nominal_moota: 0,
                    });
                  }}>
                    &times;
                </span>

                <p className="head-filter">Manual Bank Transfer</p>
                <p className="all-category">
                    <img src={foto} style={{maxWidth: 150+'px', margin: 'auto'}}/>
                </p>
                <div className="scroller-category">
                    <center>
                        Nominal Yang Akan Didonasikan : 
                        <br/>

                        <h2 style={{marginBottom: 0+'px'}}>Rp. {this.numberFormat(nominal_moota)}</h2>
                        <br/>

                        Nomor Rekening {bank_moota} :
                        <h3 style={{marginBottom: 0+'px'}}>{norek_moota}</h3>
                        <br/>

                        <b>Instruksi Pembayaran</b>
                        <br/>
                        <ol style={{textAlign: 'start', margin: '0 50px 0 50px'}}>
                          <li>Salin nomor rekening tujuan</li>
                          <li>Buka m-banking/e-banking/pergi ke ATM terdekat</li>
                          <li>Lakukan transfer ke bank tujuan {bank_moota}</li>
                          <li>Masukkan nominal <b>persis</b> seperti yang tertera di atas! <b>Rp. {this.numberFormat(nominal_moota)}</b></li>
                          <li>Status pembayaran akan berupah paling kurang lebih 15 menit dari waktu transfer</li>
                        </ol>

                        <a className="btn-dns" onClick={() => this.setState({go_paid: true})}><p>Selesaikan Pembayaran</p></a>
                    </center>
                </div>
            </div>
        </div>

        <div className="content-boxed">
          <section id="donasi-box">
            <Nominal
              label="Nominal Donasi (Rp)"
              donation_type={donation_type}
              nominal={nominals}
              callback={this.callbackNominal}
            />
            <MetodePembayaran callback={this.callbackVendor} />

            <div id="btn_for_login">
              <br />
              <center style={{ fontSize: 12 + "px", color: "#8a8a8a" }}>
                Silahkan isi data di bawah ini
              </center>
            </div>

            <Input
              type="text"
              name="full_name"
              value={full_name}
              placeholder="Nama Lengkap Donatur"
              callback={this.callbackInput}
            />
            <Input
              type="number"
              name="phone_number"
              value={phone_number}
              placeholder="Nomor Ponsel atau Whatsapp"
              callback={this.callbackInput}
            />
            <Input
              type="email"
              name="email"
              value={email}
              placeholder="Email"
              callback={this.callbackInput}
            />

            <p
              className="label-form"
              id="label_phone"
              style={{ display: "none" }}
            >
              Nomor Telepon E-Wallet
            </p>
            <input
              type="number"
              name="phone"
              id="phone"
              className="input-form"
              value={phone}
              onChange={(e) => this.handleInputChange(e)}
              style={{ display: "none" }}
            />

            {/* credit_card */}
            {/* <p className="label-form" id="label_phone"></p> */}
            <input
              type="number"
              name="card_number"
              placeholder="Card Number"
              id="card_number"
              className="input-form"
              value={card_number}
              onChange={(e) => this.handleInputChange(e)}
            />

            {/* <p className="label-form" id="label_phone"></p> */}
            <input
              type="number"
              name="exp_month"
              placeholder="Expired Month"
              id="exp_month"
              className="input-form"
              max="12"
              value={exp_month}
              onChange={(e) => this.handleInputChange(e)}
            />

            {/* <p className="label-form" id="label_phone"></p> */}
            <input
              type="number"
              name="exp_year"
              placeholder="Expired Year"
              id="exp_year"
              className="input-form"
              max="2100"
              value={exp_year}
              onChange={(e) => this.handleInputChange(e)}
            />

            {/* <p className="label-form" id="label_phone"></p> */}
            <input
              type="number"
              name="cvn"
              placeholder="CVN"
              id="cvn"
              className="input-form"
              value={cvn}
              onChange={(e) => this.handleInputChange(e)}
            />
            {/* end credit_card */}

            <br />
            <a
              className="donasi"
              onClick={() => this.doDonation()}
              style={{ cursor: "pointer" }}
            >
              <LoadingIndicator />
              {btn_donasi}
            </a>
          </section>
        </div>
      </div>
    );
  }
}

export default Donate;
