// Import React and Component
import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'
// Import CSS from App.css
// import './App.css'
// Import the Home component to be used below
import Home from './Home/Home'

import Hubungi from './Hubungi/HubungiKami'

import Program from './Program/Program'
import DetailProgram from './Program/Detail/DetailProgram'
import Donate from './Program/Donate/Donate'

import Zakat from './Zakat/Zakat'
import HitungZakat from './Zakat/Hitung/HitungZakat'
import TunaikanZakat from './Zakat/Tunaikan/TunaikanZakat'

import Qurban from './Qurban/Qurban'
import Aqiqah from './Aqiqah/Aqiqah'

import RiwayatDonasi from './Riwayat/RiwayatDonasi'
import RiwayatZakat from './Riwayat/RiwayatZakat'
import RiwayatQurban from './Riwayat/RiwayatQurban'

import Login from './Login/Login'
import Logout from './Login/Logout'
import Daftar from './Login/Daftar'
import VerifyEmail from './Login/VerifyEmail'
import LupaPassword from './Login/LupaPassword'

import AfterPay from './Notification/AfterPay/AfterPay'

import Update from './Update/Update'
import DetailUpdate from './Update/Detail/DetailUpdate'

import ProfileMizan from './ProfileMizan/ProfileMizan'

import Profile from './Profile/Profile'
import GantiPassword from './Profile/GantiPassword'

import Notification from './Notification/Notification/Notification'

import PaymentMethod from './Xendit/PaymentMethod'

import RekeningDonasi from './RekeningDonasi/RekeningDonasi'
import KantorCabang from './KantorCabang/KantorCabang'
import SyaratDanKetentuan from './SyaratDanKetentuan/SyaratDanKetentuan'
import KetentuanPrivasi from './KetentuanPrivasi/KetentuanPrivasi'
import LaporanAudit from './LaporanAudit/LaporanAudit'


class App extends Component {

  render() {
    return (
      <Router>

        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/coba">
            <h1>coba</h1>
          </Route>
          <Route path="/home" component={Home} />

          <Route path="/prog" component={Program} />
          <Route path="/programs/:seoKey" component={DetailProgram} />
          <Route path="/programs" component={Program} />
          <Route path="/programs/pencarian" component={Program} />
          <Route path="/programs/detail/:seoKey/:agency" component={DetailProgram} />
          <Route path="/detailprogram/:seoKey" component={DetailProgram} />
          <Route path="/detailprogram/:seoKey/:agency" component={DetailProgram} />
          <Route path="/donateform/:seoKey" component={Donate} />
          <Route path="/donateform/:seoKey/:agency" component={Donate} />

          <Route path="/zakat/kalkulator" component={HitungZakat} />
          <Route path="/zakat/form-zakat" component={TunaikanZakat} />
          <Route path="/zakat" component={Zakat} />

          <Route path="/form-qurban" component={Qurban} />
          <Route path="/aqiqah" component={Aqiqah} />

          <Route path="/riwayat_donasi" component={RiwayatDonasi} />
          <Route path="/riwayat_zakat" component={RiwayatZakat} />
          <Route path="/riwayat_qurban" component={RiwayatQurban} />

          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/daftar/email-verify/:token" component={VerifyEmail} />
          <Route path="/daftar" component={Daftar} />
          <Route path="/lupa_password" component={LupaPassword} />

          <Route path="/notif/notif-wait" component={AfterPay} />

          <Route exact path="/update" component={Update} />
          <Route path="/update/pencarian" component={Update} />
          <Route path="/update/:seoKey" component={DetailUpdate} />

          <Route path="/tentang" component={ProfileMizan} />

          <Route exact path="/profile" component={Profile} />
          <Route path="/profile/ganti_password" component={GantiPassword} />

          <Route path="/notification" component={Notification} />

          <Route path="/payment_method" component={PaymentMethod} />

          <Route path="/rekening-donasi" component={RekeningDonasi} />
          <Route path="/kantor-cabang" component={KantorCabang} />
          <Route path="/syarat-dan-ketentuan" component={SyaratDanKetentuan} />
          <Route path="/ketentuan-privasi" component={KetentuanPrivasi} />
          <Route path="/laporan-audit" component={LaporanAudit} />

          <Route path="/hubungi" component={Hubungi} />
          <Route path="/hub" component={Hubungi} />

        </Switch>
      </Router>
    );
  }
}

export default App