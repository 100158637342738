import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../Constant'
import { askForPermissioToReceiveNotifications } from '../push-notification'
import axios from 'axios'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class GantiPassword extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            password_lama: '',
            password_baru: '',
            password_baru_confirm: '',

            go_home: false
        }
    }

    componentDidMount() {
        this.getUser()
    }

    getUser(){
        axios.get(Constant.GET_USER, {
            headers: {
                'Authorization': cookies.get('accessToken')
            }
        }).then(response => {
            this.setState({email: response.data.email})
        }).catch(error => {
            this.setState({go_home: true})
        })
    }

    handleInputChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value})
    }
    checkPass(){
        const {
            email, password_lama, password_baru, password_baru_confirm
        } = this.state

        if(password_lama == null || password_lama == ''){
            alert('Password Lama Tidak Boleh Kosong')
        }else if(password_baru == null || password_baru == ''){
            alert('Password Baru Tidak Boleh Kosong')
        }else if(password_baru_confirm == null || password_baru_confirm == ''){
            alert('Konfirmasi Password Baru Tidak Boleh Kosong')
        }else if(password_baru != password_baru_confirm){
            alert('Password Baru Tidak Tidak Sama')
        }else{
            const parameter = {
                "email": email,
                "password": password_lama
            }
    
            axios.post(Constant.CHECK_PASSWORD, parameter)
            .then(response => {
                if(response.data.status == 200){
                    this.changePassword()
                }else{
                    alert('Password Lama Salah !')
                }
            })
        }
    }
    changePassword(){
        const {
            password_baru, password_baru_confirm
        } = this.state

        const paramter = {
            "password": password_baru
        }

        axios.post(Constant.CHANGE_PASSWORD, paramter, {
            headers: {
                'Authorization': cookies.get('accessToken')
            }
        })
        .then(response => {
            alert('Password Telah Diperbarui')
            this.setState({go_home: true})
        })
    }

    render() {
        const {
            password_lama, password_baru, password_baru_confirm, go_home
        } = this.state
        
        if (go_home)
            return <Redirect exact to="/home" />

        return(
        <div>
            <header id="header">
				<nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({go_home:true})}>
                    </i>
                    Ubah Password
				</nav>
			</header>
            
			<section id="donasi-box">
                <center><p className="title-donasi">Ubah Password</p></center>
                <br/>

                <form>
                    <input 
                        type="password"
                        name="password_lama" 
                        id="password_lama" 
                        className="input-form"
                        placeholder="Password Lama"
                        value={password_lama}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <input 
                        type="password"
                        name="password_baru" 
                        id="password_baru" 
                        className="input-form"
                        placeholder="Password Baru"
                        value={password_baru}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <input 
                        type="password"
                        name="password_baru_confirm" 
                        id="password_baru_confirm" 
                        className="input-form"
                        placeholder="Konfirmasi Password Baru"
                        value={password_baru_confirm}
                        onChange={(e) => this.handleInputChange(e)}
                    />

                    <br/><br/>
                    
                    <a 
                        className="btn-dns" 
                        style={{padding: "10px"}}
                        onClick={() => this.checkPass()}
                    >
                        <p>Ubah Password</p>
                    </a>
                </form>
                <br/><br/>
               
            </section>
        </div>
        )
    }
}

export default GantiPassword