import React, { Component } from 'react'
// import * as Constant from '../Constant'
import {
    Redirect, Link
} from 'react-router-dom'
import Header from '../../Components/Header'
import Mitra from '../../Components/MitraPerusahaan'

class AfterPay extends Component {
    constructor() {
        super()
        this.state = {
            go_home: false,
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none";
    }
    componentWillUnmount() {
        // 
    }

    render() {
        const { go_home } = this.state

        if (go_home)
            return <Redirect exact to='/home' />

        return(
        <div className="page-content" style={{paddingBottom: 0}}>         
            {/* <header id="header">
                <nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({ go_home: true })}>
                    </i>
                    Notifikasi
                </nav>
            </header> */}

            {/* <div className="header header-fixed header-logo-app">
                <i id="ic-sidebar" className="fa fa-arrow-left" onClick={() => this.setState({ go_back: true })} style={{padding: 20+"px"}}></i>Notifikasi
            </div>
            <br/><br/> */}

            <Header />

            <div className="content-boxed">
                <center>
                    <div style={{width: 100+'px'}}>
                        <img src="/bell.png" style={{marginTop: 20+'px', width: 50+'px'}}/>
                    </div>
                </center><br/>
                <center>
                    <h5 className="notif-transaksi">Notifikasi Transaksi</h5>
                    <div className="notif-desc">
                        <p>
                        Terima kasih atas donasi dan partisipasi anda. Update Total donasi dalam 1X24 Jam. Semoga Allah membalas dengan pahala yang berlipat.
                        </p>
                    </div>
                </center>


                <Link to="/" className="payment-method" style={{padding: '10px', margin: '20px'}}>
                    Kembali Ke Home
                </Link>
            </div>

            <Mitra/>
            <footer style={{margin: 10+'px', padding: 10+'px'}}>
                <center><p>Copyrights © 2020 All Rights Reserved by Wakaf Cendekia Indonesia.</p></center>
            </footer>
        </div>
        )
    }
}

export default AfterPay