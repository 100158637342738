import React, { Component } from 'react'
import {
    Redirect,
    Link
} from "react-router-dom"
import Cookies from 'universal-cookie'
import Axios from 'axios';
import * as Constant from '../Constant'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div style={{
            width: '100%',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px'
        }}>
            <Loader type="Oval" color={'#313131'} height="50" width="50" />
        </div>
    )
}

const cookies = new Cookies();
class PaymentMethod extends Component {
    constructor() {
        super()
        this.state = {
            vendors: [],
            vendors_child: [],
            selected_method: 0,
            selected_vendor: null,

            go_back: false
        }
    }
    componentDidMount() {
        document.body.style.backgroundImage = "none";
        this.getVendors()
    }
    getVendors() {
        const array = this.state.vendors
        trackPromise(
            Axios.get(Constant.GET_VENDORS)
                .then(response => {
                    // this.setState({vendors: response.data.data})
                    let res = response.data.data
                    res.map((result, i) => {
                        Axios.get(Constant.GET_VENDORS_CHILD + result.id + '?yayasan=' + Constant.KEY_YAYASAN)
                            .then(response2 => {
                                if (response2.data.data.length > 0) {
                                    const array2 = {
                                        "id": result.id,
                                        "category": result.category,
                                        "anak": response2.data.data
                                    }
                                    array.push(array2)
                                    this.setState({ vendors: array })
                                }
                            }).catch(error2 => {
                                console.log(error2)
                            })
                    })

                    this.getVendorsChild()
                })
                .catch(error => {
                    console.log(error)
                })
        )
    }
    getVendorsChild() {
        const { vendors } = this.state

        vendors.map((vendors, i) => {
            Axios.get(Constant.GET_VENDORS_CHILD + vendors.id + '?yayasan=' + Constant.KEY_YAYASAN)
                .then(response => {
                    this.setState({ vendors_child: response.data.data })
                })
                .catch(error => {
                    console.log(error)
                })
        })
    }

	dataCallback = (v) => {
        this.props.callbackVendor(v, v.id, false);
	}

    render() {
        const {
            vendors
        } = this.state
        return (
            <>
                <LoadingIndicator />
                {vendors.length > 0 &&
                    <div>
                        {vendors.map((vendor, i) => {
                            return (
                                <div key={i} style={{ margin: 5 + "px", padding: 5 + "px" }}>
                                    <hr style={{ marginTop: 0 + "px", marginBottom: 0 + "px" }} />
                                    <h6 style={{ color: '#000000', fontSize: '14px' }}>{vendor.category}</h6>
                                    {vendor.anak.map((child, ii) => {
                                        let foto = Constant.GET_PICT_VENDOR + child.id
                                        return (
                                            <div key={ii}>
                                                <table style={{ marginBottom: 0 + 'px' }}>
                                                    <tbody>
                                                        <tr onClick={() => this.dataCallback(child)}>
                                                            <td width="30%">
                                                                <img src={foto} style={{ maxWidth: 80 + 'px' }} />
                                                            </td>
                                                            <td style={{ verticalAlign: 'middle' }}>
                                                                <label style={{fontSize: '12px'}}>{child.payment_name}</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    })}

                                    {/* <hr style={{marginTop: 0+"px", marginBottom: 0+"px"}} /> */}
                                </div>
                            )
                        })}
                    </div>
                }
            </>
        )
    };
}

export default PaymentMethod;