import React, { Component } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import * as Constant from '../Constant';
import { Plus, Minus } from 'react-feather'
Modal.setAppElement('#root');

const customStyles = {
    content: {
        position: 'relative',
        top: '25px',
        bottom: '10px',
        right: '0px',
        width: '290px',
        border: '0px',
        borderRadius: '15px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        zIndex: 100
    },
    overlay: {
        zIndex: 99,
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
    }
};

class Nominal extends Component {
    constructor() {
        super();
        this.state = {
            donation: 0
        }
    }
    handleInputChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }
    onAmountChanged(e) {
        const { name, value } = e.target
        this.setState({ [name]: parseInt(value.split('.').join('').split(',').join('')) })
        this.props.callback(parseInt(value.split('.').join('').split(',').join('')))
    }
    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    onSuggestButtonClicked(value) {
        this.setState({ donation: parseInt(value.replace(/\./g, '')) })
        this.props.callback(parseInt(value.replace(/\./g, '')))
    }
    onPlusButtonClicked(value) {
        const { donation } = this.state
        this.setState({ donation: donation+value })
        this.props.callback(donation+value)
    }
    onMinusButtonClicked(value) {
        const { donation } = this.state
        let cek = donation-value;
        if(cek < value) {
            this.setState({ donation: value })
            this.props.callback(value)
        } else {
            this.setState({ donation: donation-value })
            this.props.callback(donation-value)
        }
    }
    render() {
        const {
            donation
        } = this.state
        let label = null;
        let nominals = null;
        let donation_type = null;
        let key = [1, 2, 3, 4]
        let value = 0
        if (this.props.label !== null || this.props.label === []) {
            label = this.props.label;
        }
        if (this.props.nominal !== null || this.props.nominal === []) {
            nominals = this.props.nominal;
        }
        if (this.props.donation_type !== null) {
            donation_type = this.props.donation_type;
            value = this.props.donation_type.value
        }
        return (
            <>
                {nominals.length > 0 &&
                    <>
                        <p style={{ marginBottom: 0 }}>{label} </p>
                        <b><input type="text" name="donation" style={{ borderRadius: 10 + 'px !important', marginBottom: 5 + 'px', padding: 10 + 'px' }} id="donation" className="input-form-donasi" value={this.numberFormat((donation) ? donation : 0)} onChange={(e) => this.onAmountChanged(e)} /></b>
                        <div className="sugest-box">
                            {nominals.map((nominal, i) => {
                                return (
                                    <a key={i} className="button-donasi" style={{ padding: 5 + "px" }} onClick={() => this.onSuggestButtonClicked(nominal.value)}>
                                        {nominal.value}
                                    </a>
                                )
                            })}
                        </div>
                    </>
                }
                {donation_type !== null &&
                    <>
                        {donation_type.type === "minimum" &&
                            <>
                                <p style={{ marginBottom: 0 }}>{label} </p>
                                <b><input type="text" name="donation" style={{ borderRadius: 10 + 'px !important', marginBottom: 5 + 'px', padding: 10 + 'px' }} id="donation" className="input-form-donasi" value={this.numberFormat((donation) ? donation : 0)} onChange={(e) => this.onAmountChanged(e)} /></b>
                                <div className="sugest-box">
                                    {key.map((val, i) => {
                                        value = value * val
                                        let nominal = this.numberFormat(value)
                                        return (
                                            <a key={i} className="button-donasi" style={{ padding: 5 + "px" }} onClick={() => this.onSuggestButtonClicked(nominal)}>
                                                {nominal}
                                            </a>
                                        )
                                    })}
                                </div>
                            </>
                        }
                        {donation_type.type === "kelipatan" &&
                            <>
                                <p style={{ marginBottom: 0 }}>{label} </p>
                                <b>
                                    <div style={{ display: 'flex' }}>
                                        <a className="button-donasi" style={{marginTop: '0px', marginBottom: 5 + 'px', padding: 10 + 'px', height: '42px'}} onClick={() => this.onMinusButtonClicked(donation_type.value)}>
                                            <Minus />
                                        </a>
                                        <input type="text" name="donation" style={{ borderRadius: 10 + 'px !important', marginBottom: 5 + 'px', marginLeft: '10px', marginRight: '10px', padding: 10 + 'px' }} id="donation" className="input-form-donasi" value={this.numberFormat((donation) ? donation : 0)} onChange={(e) => this.onAmountChanged(e)} readOnly />
                                        <a className="button-donasi" style={{marginTop: '0px', marginBottom: 5 + 'px', padding: 10 + 'px', height: '42px'}} onClick={() => this.onPlusButtonClicked(donation_type.value)}>
                                            <Plus />
                                        </a>
                                    </div>
                                </b>
                            </>
                        }
                    </>
                }
                <br />
            </>
        )
    };
}

class Input extends Component {
    constructor(props) {
        super(props)

    }
    handleInputChange(e) {
        const { name, value } = e.target
        this.props.callback(name, value);
    }
    render() {
        let type, placeholder, name, value;
        if (this.props.type !== null) {
            type = this.props.type
        }
        if (this.props.placeholder !== null) {
            placeholder = this.props.placeholder
        }
        if (this.props.name !== null) {
            name = this.props.name
        }
        if (this.props.value !== null) {
            value = this.props.value
        }
        return (
            <>
                <input type={type} placeholder={placeholder} name={name} value={value} className="input-dr-donasi" onChange={(e) => this.handleInputChange(e)} />
            </>
        )
    };
}

class MetodePembayaran extends Component {
    constructor() {
        super()
        this.state = {
            metode_pembayaran: 'Pilih Metode Pembayaran',
            logo_payment: 'none',
            setIsOpen: false,
            vendors: [],
            vendors_child: [],
            selected_method: 0,
            selected_vendor: null,
            no_rekening: ''
        }
    }

    componentDidMount() {
        this.getVendors()
    }

    callback = (v) => {
        let rek = '';
        if (v.bank_rekening_moota !== null) {
            rek = v.bank_rekening_moota.no_rekening;
        }
        this.setState({ setIsOpen: false, logo_payment: 'block', selected_method: v.id, selected_vendor: v, no_rekening: rek })
        this.props.callback(v, v.id, rek)
    }

    getVendors() {
        const array = this.state.vendors
        axios.get(Constant.GET_VENDORS).then(
            response => {
                let res = response.data.data
                res.map((result, i) => {
                    axios.get(Constant.GET_VENDORS_CHILD + result.id + '?yayasan=' + Constant.KEY_YAYASAN).then(
                        response2 => {
                            let res2 = response2.data.data
                            if (res2.length > 0) {
                                const array2 = {
                                    "id": result.id,
                                    "category": result.category,
                                    "anak": response2.data.data
                                }
                                array.push(array2)
                                this.setState({ vendors: array })
                            }
                        }
                    )
                })
                this.getVendorsChild()
            }
        )
    }

    getVendorsChild() {
        const { vendors } = this.state
        vendors.map((vendors, i) => {
            axios.get(Constant.GET_VENDORS_CHILD + vendors.id + '?yayasan=' + Constant.KEY_YAYASAN).then(
                response => {
                    this.setState({ vendors_child: response.data.data })
                }
            )
        })
    }

    render() {
        const {
            metode_pembayaran, logo_payment, setIsOpen, vendors, selected_vendor
        } = this.state
        return (
            <>
                <table style={{ marginBottom: 0 + 'px', display: logo_payment }} border="1px" id="logo_payment">
                    <tbody>
                        <tr>
                            <td width="30%">
                                {
                                    selected_vendor ?
                                        <img src={Constant.GET_PICT_VENDOR + selected_vendor.id} style={{ maxWidth: 80 + 'px' }} />
                                        :
                                        <img src={''} style={{ maxWidth: 80 + 'px' }} />
                                }
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                                { (selected_vendor) ? selected_vendor.payment_name : '' }
                            </td>
                        </tr>
                    </tbody>
                </table>

                <button className="payment-method" style={{ width: '100%', cursor: 'pointer' }} onClick={() => this.setState({ setIsOpen: true })}>{metode_pembayaran}</button>

                <Modal
                    isOpen={setIsOpen}
                    onRequestClose={() => this.setState({ setIsOpen: false })}
                    contentLabel="Example Modal"
                    style={customStyles}
                    className="menu round-medium res"
                    closeTimeoutMS={300}>
                    <div className="res">
                        <div className="filter">
                            <h5 style={{ marginBottom: 15 + 'px' }}>Pilih Metode Pembayaran</h5>
                            {vendors.length > 0 &&
                                <>
                                    {vendors.map((vendor, i) => {
                                        return (
                                            <div key={i} style={{ margin: 5 + "px", padding: 5 + "px" }}>
                                                <hr style={{ marginTop: 0 + "px", marginBottom: 0 + "px" }} />
                                                <h6 style={{ color: '#000000', fontSize: '14px' }}>{vendor.category}</h6>
                                                {vendor.anak.map((child, ii) => {
                                                    let foto = Constant.GET_PICT_VENDOR + child.id
                                                    return (
                                                        <div key={ii}>
                                                            <table style={{ marginBottom: 0 + 'px' }}>
                                                                <tbody>
                                                                    <tr onClick={() => this.callback(child)}>
                                                                        <td width="30%">
                                                                            <img src={foto} style={{ maxWidth: 80 + 'px' }} />
                                                                        </td>
                                                                        <td style={{ verticalAlign: 'middle' }}>
                                                                            <label style={{ fontSize: '12px' }}>{child.payment_name}</label>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </>
                            }
                        </div>
                    </div>
                </Modal>
            </>
        )
    };
}

export { Nominal, Input, MetodePembayaran }