import React, { Component } from 'react'
import {
    Link
} from 'react-router-dom'
import * as Constant from '../Constant'
import axios from 'axios'
// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import ReactHtmlParser from 'react-html-parser'
import Cookies from 'universal-cookie';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
//
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #3DADAA;
`;


const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div style={{
            width: '100%',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Loader type="ThreeDots" color={'#ea6123'} height="60" width="60" />
        </div>
    )
}

const customStyles = {
    content: {
        top: '10px',
        bottom: '10px',
        right: '0px',
        width: '260px',
        overflow: 'scroll',
        border: '0px',
        borderRadius: '15px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        zIndex: 100
    },
    overlay: {
        zIndex: 99,
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
    },
};

const customClass = {
    base: 'React__Filter__Content',
    afterOpen: 'React__Filter__Content--after-open'
}

const cookies = new Cookies();
class Update extends Component {
    constructor() {
        super()
        this.state = {
            categories: [],
            updates: [],
            page: 1,
            last_page: 1,
            search: null,
            category_id: null,
            go_home: false,
            go_detail: false,
            seo_key: '',
            loading: true,

            go_donate: false,
            go_profile: false,
            go_posting: false,
            categoryf: '',

            isAvailable: '',
            setIsOpen: false
        }

        this.timer = null
        this.trackScrolling = this.trackScrolling.bind(this)
    }
    componentDidMount() {
        this.getId()
        if (cookies.get('id_yayasan') != null) {
            this.getCategories()
            this.checkCategoryFromProps()
        }

        document.addEventListener('scroll', this.trackScrolling)

        // Modal Filter Close
        window.onclick = function (event) {
            if (event.target == document.getElementById("menu-main")) {
                document.getElementById("menu-hider")
            }
        }

        if (this.props.location.search != null) {
            this.setValueFromQueryUrl()
        }
    }

    getId() {
        if (cookies.get('id_yayasan') == null) {
            axios.get(Constant.GET_YAYASAN, {
                headers: {
                    'X-API-KEY': Constant.X_API_KEY
                }
            })
                .then(res => {
                    var idc = res.data.data.id;
                    cookies.set('id_yayasan', idc);
                    this.getCategories()
                    this.checkCategoryFromProps()
                })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)
    }
    trackScrolling() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.getElementById("programs_scroller")
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        if (windowBottom >= docHeight &&
            this.state.page != this.state.last_page) {
            let next = this.state.page + 1
            this.setState({
                page: next,
                loading: true
            })
            this.getUpdates()
        }
    }
    handleInputChange(e) {
        clearTimeout(this.timer)

        const { name, value } = e.target
        this.setState({ [name]: value, page: 1 })

        this.timer = setTimeout(() => this.getUpdates(), 1000)
    }
    handleKeyDown(e) {
        if (e.keyCode === 13) {
            clearTimeout(this.timer)

            this.setState({ show_search: false })
            this.getUpdates()
        }
    }
    categorySelected(category_id, categoryf) {
        this.setState({ category_id: category_id, categoryf: categoryf, updates: [], setIsOpen: false })
        //this.getCategories()
        this.timer = setTimeout(() => this.getUpdates(), 250)

    }

    getCategories() {
        // get categories
        axios.get(Constant.GET_POSTING_CATEGORIES + '?id_yayasan=' + cookies.get('id_yayasan'))
            .then(response => {
                // console.log(response.data)
                this.setState({ categories: response.data.data });
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    getUpdates() {
        const { page, search, category_id, category_id_before } = this.state

        let halaman = 1;
        if (category_id != category_id_before) {
            this.setState({
                page: 1
            })
            halaman = 1
        } else {
            halaman = page
        }

        let query_url = `?page=${halaman}`
        query_url += `&id_yayasan=${cookies.get('id_yayasan')}`
        if (search)
            query_url += `&keyword=${search}`
        if (category_id)
            query_url += `&idKategori=${category_id}`

        this.setState({
            category_id_before: category_id, isAvailable: ''
        })

        trackPromise(
            axios.get(Constant.GET_UPDATES + query_url)
                .then(response => {
                    // console.log(response.data)
                    if (page == 1) {
                        this.setState({
                            updates: response.data.data,
                            last_page: response.data.last_page,
                            loading: false
                        })
                    } else {
                        if (category_id != category_id_before) {
                            console.log('timpa')
                            this.setState({
                                updates: response.data.data,
                                last_page: response.data.last_page,
                                loading: false,
                                page: 1
                            })
                            console.log(response.data.data)
                        } else {
                            console.log('tambah')
                            let newUpdates = this.state.updates.concat(response.data.data)
                            // console.log(newUpdates)
                            this.setState({
                                updates: newUpdates,
                                last_page: response.data.last_page,
                                loading: false
                            })
                            console.log(response.data.data)
                        }
                    }

                    if (response.data.data.length === 0) {
                        this.setState({ isAvailable: 'Tidak ada data posting.' })
                    }
                })
                // Catch any error here
                .catch(error => {
                    console.log(error)
                })
        )

    }
    setValueFromQueryUrl() {
        const { page, search, category_id } = this.state

        let url = this.props.location.search
        let split_url = String(url).substring(1).split('&')

        // console.log(split_url)
        split_url.map((query) => {
            console.log(query)
            let pisah = query.split('=')
            if (pisah[0] == "page") {
                this.setState({ page: pisah[1] })
            } else if (pisah[0] == "search_query_first") {
                this.setState({ search: pisah[1] })
            } else if (pisah[0] == "search_query_last") {
                this.setState({ category_id: pisah[1] })
            }
        })
    }

    checkCategoryFromProps() {
        let props_category = (this.props.location.state)
            ? this.props.location.state.category_id
            : null

        if (props_category) this.setState({ category_id: props_category })

        this.timer = setTimeout(() => this.getUpdates(), 250)
    }
    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    dateDiff(startDate, endDate) {
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24))
    }
    dateFormat(date) {
        if (date) {
            let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu']
            let months = [
                'Januari', 'Februari', 'Maret',
                'April', 'Mei', 'Juni', 'Juli',
                'Agustus', 'September', 'Oktober',
                'November', 'Desember'
            ]

            let new_date = new Date(date)
            let formatted_date = days[new_date.getDay()] + ", "
                + new_date.getDate() + " "
                + months[new_date.getMonth()] + " "
                + new_date.getFullYear()

            return formatted_date
        } else {
            return "Tanpa Batas Waktu"
        }
    }

    render() {
        const { match } = this.props
        const {
            categories, search, category_id,
            go_home, go_detail, seo_key, go_donate,
            go_profile, go_posting, categoryf,
            isAvailable
        } = this.state

        return (
            <div>
                <div>
                    <div className="header header-fixed header-logo-app header-auto-show">
                        <div className="res">
                            <Link to="/">
                                <i id="ic-sidebar" className="fa fa-arrow-left" style={{ padding: 20 + "px" }}></i>
                            Daftar Posting
                            </Link>
                        </div>
                    </div>
                    <Header />

                    <div className="content">
                        <div style={{ display: 'flex' }}>
                            <div className="search-box search-color shadow-tiny round-huge bottom-0 bg-in">
                                <i className="fa fa-search"></i>
                                <input type="text" name="search" id="search" placeholder="Cari Posting ... " value={search ? search : ''} onChange={(e) => this.handleInputChange(e)} onKeyDown={(e) => this.handleKeyDown(e)} data-search />
                            </div>
                            <div className="set-icon" style={{ color: '#fff' }}>
                                <i className="fa fa-filter fa-lg" onClick={() => this.setState({ setIsOpen: true })} style={{ cursor: 'pointer' }}></i>
                            </div>
                        </div>
                    </div>

                    <div className="page-content">

                        <div className="scroller-program" id="programs_scroller">
                            <div className="content-boxed">
                                <center>
                                    <label style={{ fontWeight: 'bold', fontSize: '22px', color: '#ea6123' }}>Daftar Posting</label>
                                    <p style={{ color: '#ea6123', fontSize: '16px', fontWeight: 'bold' }}>{categoryf == "" || categoryf == null ? 'Kategori: Semua' : 'Kategori: ' + categoryf}</p>
                                    <LoadingIndicator />
                                </center>
                            </div>
                            {this.state.updates.length > 0 ?
                                this.state.updates.map((update, i) => {
                                    let image_link = Constant.GET_UPDATES_IMAGE + update.id_posting
                                    return (
                                        <Link to={'/update/' + update.seo} key={i}>
                                            <div className="content-boxed" style={{ paddingBottom: '0px' }}>
                                                <div className="content" style={{ display: 'flex' }}>
                                                    <img className="update-list-image" src={image_link} />
                                                    <div className="update-list-desc">
                                                        <h6 className="update-list-title">{update.judul}</h6>
                                                        <i><label className="category-program">{update.posting.category.category}</label></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                                :
                                <div className="content-boxed">
                                    <center>
                                        <p style={{ color: '#ea6123', fontSize: '16px', fontWeight: 'bold' }}>{isAvailable}</p>
                                    </center>
                                </div>
                            }

                        </div>

                    </div>

                </div>
                <Footer />

                <Modal
                    isOpen={this.state.setIsOpen}
                    onRequestClose={() => this.setState({ setIsOpen: false })}
                    contentLabel="Example Modal"
                    style={customStyles}
                    className="menu menu-box-right round-medium res"
                    closeTimeoutMS={300}>
                    <div className="res">
                        <div className="filter">
                            <div>
                                <h3 style={{ marginBottom: 30 + 'px' }}>Kategori</h3>
                                <div>
                                    {categories.map((category, i) => {
                                        return (
                                            <div key={i} style={{ cursor: 'pointer'}}>
                                                <p value={category.id} onClick={() => this.categorySelected(category.id, category.category)}>{category.category}</p>
                                                <hr style={{ margin: 0 }} />
                                            </div>
                                        )
                                    })}
                                </div>
                                <div style={{ marginTop: 50 + 'px', bottom: 0 + 'px', cursor: 'pointer' }}>
                                    <p className="filter-btn" onClick={() => this.categorySelected(null)}>Semua Kategori</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div >
        )
    }
}

export default Update