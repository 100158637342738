import React, { Component } from 'react'
import {
    Redirect,
    Link
} from 'react-router-dom'
import * as Constant from '../Constant'
import { askForPermissioToReceiveNotifications } from '../push-notification'
import axios from 'axios'
import Cookies from 'universal-cookie';
import Header from '../Components/Header';

import { MetodePembayaran, Input, Nominal } from '../Components/FormComponent';
const cookies = new Cookies();

class LupaPassword extends Component {
    constructor() {
        super()
        this.state = {
            email: '',

            go_back: false,
            go_home: false
        }
    }

    componentDidMount() {

    }

    handleInputChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    send() {
        const {
            email
        } = this.state

        if (email == null || email == '') {
            alert('Email Tidak Boleh Kosong')
        } else {
            const parameter = {
                "email": email
            }

            axios.post(Constant.FORGOT_PASSWORD, parameter).
                then(response => {
                    if (response.data.status == 400) {
                        alert(response.data.message)
                    } else {
                        alert('Email Terkirim, mohon cek email untuk reset password !')
                        this.setState({ go_home: true })
                    }
                }).catch(error => {
                    console.log(error)
                })
        }
    }
    callbackInput = (name, value) => {
        this.setState({ [name]: value })
    }
    render() {
        const {
            email, go_back, go_home
        } = this.state

        if (go_back)
            return <Redirect exact to="/login" />
        else if (go_home)
            return <Redirect exact to="/home" />

        return (
            <div className="page-content">
                <Header/>
                <div className="content-boxed">
                    <section id="donasi-box">
                        <center><label style={{ fontWeight: 'bold', fontSize: '22px', color: '#FFD52F' }}>Lupa Password</label></center>
                        <Input type="email" name="email" value={email} placeholder="Masukkan Email" callback={this.callbackInput} />
                        <a className="donasi" onClick={() => this.send()} style={{ cursor: 'pointer' }}>CONTINUE</a>
                   </section>
                </div>
            </div>
        )
    }
}

export default LupaPassword