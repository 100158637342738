import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import * as Constant from "../../Constant";
import axios from "axios";
import { Collapse } from "react-collapse";
import Header from "../../Components/Header";

// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #3dadaa;
`;

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Loader type="Oval" color={"#ea6123"} height="80" width="80" />
      </div>
    )
  );
};

class DetailProgram extends Component {
  constructor() {
    super();
    this.state = {
      program: {},
      image_link: "",

      target: 0,
      collected: 0,
      percentage: 0,
      final_day: Date.now(),
      donatur: 0,

      total_donatur: 0,
      donaturs: [],
      infoProgram: [],

      page: 1,
      last_page: 1,
      loading: true,

      go_back: false,
      go_donate: false,
      program_loading: true,

      collapse_perkembangan: false,
      collapse_donatur: false,

      user_ip: '',
    };

    this.trackScrolling = this.trackScrolling.bind(this);
  }

  componentDidMount() {
    // get user IP address
    axios
      .get("https://api.ipify.org/?format=json")
      .then((res) => {
        this.setState({
          user_ip: res.data.ip,
        });

        this.getProgramDetail();
      });

    document.body.style.backgroundImage = "none";

    document.addEventListener("scroll", this.trackScrolling);

    window.onpopstate = this.onBackButtonEvent;
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.getElementById("programs_scroller");
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight && this.state.page != this.state.last_page) {
      // console.log(windowBottom + " == " + docHeight)
      // reach to bottom
      let next = this.state.page + 1;
      this.setState({
        page: next,
        loading: true,
      });
      this.getDonaturs();
    }
  }
  getProgramDetail() {
    let {
      match: { params },
    } = this.props;
    const { user_ip } = this.state;

    trackPromise(
      axios
        .get(Constant.GET_PROGRAM_DETAIL + params.seoKey, {
          headers: {
            'X-USR-IP': user_ip
          }
        })
        .then((response) => {
          // console.log()
          let program = response.data.data;
          let target = program.program.dana_target;
          let collected = program.program.donatur[0]
            ? program.program.donatur[0].total
            : 0;

          let percentage = 0;

          if (
            program.program.dana_target != null ||
            program.program.dana_target >= 0
          ) {
            percentage = (collected / target) * 100;
          } else {
            percentage = 100;
          }

          let tanggal_akhir = null;

          if (!response.data.data.program.tanggal) {
            tanggal_akhir = null;
          } else {
            tanggal_akhir = new Date(response.data.data.program.tanggal);
          }

          this.setState({
            program: program,
            image_link:
              Constant.GET_PROGRAMS_IMAGE + response.data.data.id_program,

            target: target,
            collected: collected,
            percentage: percentage,

            final_day: tanggal_akhir,
          });

          if (response.data.data.program.donatur[0]) {
            this.setState({
              donatur: response.data.data.program.donatur[0].donatur,
            });
          }
          // console.log('tgl', final_day)
          this.getInfoProgram();
          this.countDonatur();
          this.getDonaturs();
        })
        // Catch any error here
        .catch((error) => {
          console.log(error);
        })
    );
  }

  getInfoProgram() {
    const { program, page } = this.state;
    let query_url = `?page=${page}`;
    trackPromise(
      axios
        .get(Constant.GET_INFO_PROGRAM + program.id_program + query_url)
        .then((response) => {
          if (page == 1) {
            this.setState({
              infoProgram: response.data.data,
            });
          } else {
            let newInfo = this.state.infoProgram.concat(response.data.data);
            this.setState({
              infoProgram: newInfo,
            });
          }
        })
    );
  }
  getDonaturs() {
    const { program, page } = this.state;
    let query_url = `?page=${page}`;
    trackPromise(
      axios
        .get(Constant.GET_DONATURS + program.id + query_url)
        .then((response) => {
          if (page == 1) {
            this.setState({
              donaturs: response.data.data,
              last_page: response.data.last_page,
              loading: false,
              program_loading: false,
            });
          } else {
            let newdonaturs = this.state.donaturs.concat(response.data.data);
            this.setState({
              donaturs: newdonaturs,
              last_page: response.data.last_page,
              loading: false,
              program_loading: false,
            });
          }
        })
        .catch((error2) => {
          console.log(error2);
        })
    );
  }
  countDonatur() {
    const { program } = this.state;
    axios
      .get(Constant.COUNT_DONATURS + program.id)
      .then((response3) => {
        this.setState({
          total_donatur: response3.data.data,
        });
      })
      .catch((error3) => {
        console.log(error3);
      });
  }
  numberFormat(value) {
    return value
      .toLocaleString(navigator.language, { minimumFractionDigits: 0 })
      .replace(/\,/g, ".");
  }
  dateFormat(date) {
    if (date) {
      let days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jum`at",
        "Sabtu",
      ];
      let months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      let new_date = new Date(date);
      let formatted_date =
        days[new_date.getDay()] +
        ", " +
        new_date.getDate() +
        " " +
        months[new_date.getMonth()] +
        " " +
        new_date.getFullYear();

      return formatted_date;
    } else {
      return "Tanpa Batas Waktu";
    }
  }
  newTabClickedW() {
    let linkWhatsapp = window.open(Constant.LINK_WA, "_blank");
    linkWhatsapp.focus();
  }
  newTabClickedI() {
    let linkInstagram = window.open(
      "https://www.instagram.com/temanpedulicom/",
      "_blank"
    );
    linkInstagram.focus();
  }
  newTabClickedF() {
    let linkFacebook = window.open(Constant.LINK_FACEBOOK, "_blank");
    linkFacebook.focus();
  }

  dateDiff(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    return Math.floor(
      (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
  }

  render() {
    const {
      match,
      match: { params },
    } = this.props;
    const {
      program,
      image_link,
      go_back,
      go_donate,
      target,
      collected,
      percentage,
      final_day,
      donatur,
      total_donatur,
      donaturs,
      program_loading,
      collapse_donatur,
      collapse_perkembangan,
      infoProgram,
    } = this.state;

    let seo = program.seo;

    let url = this.props.location.pathname.split("/");
    let pls = null;

    // let linkFacebook = "https://www.facebook.com/sharer/sharer.php?u=https://mizanamanah.or.id/detailprogram/"+program.seo
    // let linkTwitter = "http://twitter.com/intent/tweet?url="+program.judul+" Klik Link Berikut https://mizanamanah.or.id/detailprogram/"+program.seo
    // let linkEmail = "mailto:?Subject=&Body="+program.judul+" "+program.resume+" Klik Link Berikut https://mizanamanah.or.id/detailprogram/"+program.seo
    // let linkWhatsapp = "https://api.whatsapp.com/send?text="+program.judul+" "+program.resume+" Klik Link Berikut https://mizanamanah.or.id/detailprogram/"+program.seo

    if (url[3]) {
      pls = seo + "/" + url[3];
    } else {
      pls = seo;
    }

    let today = Date.now();
    let day_remaining = this.dateDiff(today, final_day);

    let donateAvaibility = "";
    if (day_remaining < 0 && final_day != null) {
      donateAvaibility = (
        <button
          style={{ textAlign: "center", width: "50%", cursor: "pointer" }}
          className="button-secondary bg-highlight button-m button-full button-round-medium shadow-small"
        >
          DONASI SEKARANG
        </button>
      );
    } else {
      donateAvaibility = (
        <Link
          to={"/donateform/" + pls}
          style={{ textAlign: "center", width: "50%" }}
          className="button bg-highlight button-m button-full button-round-medium shadow-small"
        >
          DONASI SEKARANG
        </Link>
      );
    }

    let donateButton = "";
    if (day_remaining < 0 && final_day != null) {
      donateButton = (
        <button
          style={{ textAlign: "center", width: "100%", cursor: "pointer" }}
          className="button-secondary bg-highlight button-m button-full button-round-medium shadow-small"
        >
          DONASI SEKARANG
        </button>
      );
    } else {
      donateButton = (
        <Link
          to={"/donateform/" + pls}
          style={{ textAlign: "center", width: "100%" }}
          className="button bg-highlight button-m button-full button-round-medium shadow-small"
        >
          DONASI SEKARANG
        </Link>
      );
    }

    return (
      <div className="page-content">
        <div className="header header-fixed header-logo-app header-auto-show">
          <div className="res">
            <Link to={"/programs"}>
              <i
                id="ic-sidebar"
                className="fa fa-arrow-left"
                style={{ padding: 20 + "px" }}
              ></i>
              {String(program.judul).substring(0, 32)}...
            </Link>
          </div>
        </div>
        <Header back_url="/programs" />

        <div
          className="content-boxed"
          id="programs_scroller"
          style={{ marginTop: 0 }}
        >
          <div className="content">
            <LoadingIndicator />
            {program_loading == false ? (
              <>
                <img
                  className="caption-image owl-lazy"
                  style={{ borderRadius: 13 + "px" }}
                  src={image_link}
                />
                <br />
                <h3 style={{ lineHeight: 1.2 + "em" }}>{program.judul}</h3>
                <h5 style={{ lineHeight: "1.2em" }}>{}</h5>
                <div className="float-left">
                  <p>Batas Waktu</p>
                </div>
                <div className="float-right">
                  <p>
                    {day_remaining < 0 && final_day !== null ? (
                      <>(Masa Penggalangan Dana Sudah Berakhir)</>
                    ) : (
                      <>{this.dateFormat(final_day)}</>
                    )}
                  </p>
                </div>
                <br />
                <br />
                <div className="w3-light-grey">
                  <div
                    className="w3-red"
                    style={{ height: 5 + "px", width: `${percentage}%` }}
                  ></div>
                </div>
                <p>{donatur} Donasi</p>
                <br />
                <div className="float-left">
                  <p className="txt-batas-waktu">
                    Terkumpul Rp. {this.numberFormat(collected)}
                  </p>
                </div>
                <div className="float-right">
                  <p className="txt-batas-waktu">
                    Target Rp. {target ? this.numberFormat(target) : "∞"}
                  </p>
                </div>
                <br />
                <br />
                <br />
                <div
                  className="content-detail"
                  style={{ width: 100 + "% !important" }}
                >
                  {ReactHtmlParser(program.deskripsi)}
                </div>
                <div className="content-detail">
                  <hr />
                  <div style={{ textAlign: "center" }}>
                    <h5>Hubungi kami</h5>
                    <p>
                      Mari berbagi informasi yang Insya Allah bermanfaat kepada
                      rekan dan saudara kita. Semoga menjadi amal soleh yang
                      membawa keberkahan untuk anda. klik tombol share di bawah
                      ini.
                    </p>
                  </div>

                  {/* <div style={{display: 'flex', marginTop: 10+'px'}}>
                                <div style={{marginRight: 10+'px'}}>
                                    <a href="#" onClick={() => this.newTabClickedW()}>
                                        <img src="/wa.png" style={{maxWidth: '50px'}}/>
                                    </a>
                                </div>
                                <div>
                                    <a href="#" onClick={() => this.newTabClickedI()}>
                                        <img src="/ig.png" style={{maxWidth: '50px'}}/>
                                    </a>
                                </div>
                            </div> */}
                  <table>
                    <thead>
                      <tr>
                        <td>
                          <div className="float-right">
                            <a
                              style={{ marginRight: 10 + "px" }}
                              href="#"
                              onClick={() => this.newTabClickedW()}
                            >
                              <img
                                src="/wa2.png"
                                style={{ maxWidth: "50px" }}
                              />
                            </a>
                          </div>
                        </td>
                        <td>
                          <div className="float-left">
                            <a
                              style={{ marginLeft: 10 + "px" }}
                              href="#"
                              onClick={() => this.newTabClickedF()}
                            >
                              <img
                                src="/facebook.png"
                                style={{ maxWidth: "50px" }}
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </thead>
                  </table>

                  <hr />

                  <h5
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.setState({
                        collapse_perkembangan: !collapse_perkembangan,
                      })
                    }
                  >
                    Lihat Perkembangan Program
                  </h5>
                  <Collapse isOpened={collapse_perkembangan}>
                    <div className="card-program">
                      {infoProgram.length == 0 ? (
                        <center>
                          <label>
                            Belum ada perkembangan untuk program ini
                          </label>
                        </center>
                      ) : (
                        infoProgram.map((val, i) => {
                          return (
                            <div className="item-donatur" key={i}>
                              <div className="box-nominal">
                                <label style={{ fontSize: "12px" }}>
                                  {this.dateFormat(val.created_at)}
                                </label>
                                <h5>{val.judul}</h5>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </Collapse>
                  <hr />

                  <h5
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.setState({ collapse_donatur: !collapse_donatur })
                    }
                  >
                    Donatur ({total_donatur})
                  </h5>
                  <Collapse isOpened={collapse_donatur}>
                    <div className="card-program">
                      {donaturs.map((pendonasi, i) => {
                        let foto = null;
                        if (pendonasi.guest != null) {
                          foto =
                            "https://mizanamanah.or.id/admin/assets/media/icons/icon-people.png";
                        } else {
                          if (pendonasi.user != null) {
                            foto =
                              Constant.GET_PROFILE_PICTURE + pendonasi.user.id;
                          } else {
                            foto =
                              "https://mizanamanah.or.id/admin/assets/media/icons/icon-people.png";
                          }
                        }
                        return (
                          <div className="item-donatur" key={i}>
                            <div
                              className="img-donatur"
                              style={{ backgroundImage: `url(${foto})` }}
                            ></div>
                            <div className="box-nominal">
                              {pendonasi.guest
                                ? pendonasi.guest.nama_lengkap
                                : pendonasi.user
                                ? pendonasi.user.name
                                : "-"}
                              <h5>
                                Rp. {this.numberFormat(pendonasi.total_donasi)}
                              </h5>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Collapse>
                </div>
              </>
            ) : (
              <>
                <div>
                  <center>
                    <label style={{ marginTop: "10px", color: "#ea6123" }}>
                      Sedang Mengunduh Data Program
                    </label>
                  </center>
                </div>
              </>
            )}

            {/* {this.numberFormat(program.total)} dari Rp. {(program.dana_target) ? this.numberFormat(program.dana_target) : "∞"} */}
          </div>
        </div>
        {/* <div onClick={() => this.setState({ go_donate: true })} className="bg-donasi" style={{width: '100%'}}>
                            <a href="#" style={{ textAlign: 'center' }} className="button bg-highlight button-m button-full button-round-medium shadow-small">DONASI SEKARANG</a>
                        </div>
                        <div onClick={() => this.setState({ go_donate: true })} className="bg-donasi">
                            <a href="#" style={{ textAlign: 'center' }} className="button bg-highlight button-m button-full button-round-medium shadow-small">DONASI SEKARANG</a>
                        </div> */}
        {program_loading == false && (
          <>
            <div className="bg-donasi" style={{ width: "100%" }}>
              {/* <Link
                to={"/donateform/" + pls}
                style={{ textAlign: "center" }}
                className="button bg-highlight button-m button-full button-round-medium shadow-small"
              >
                DONASI SEKARANG
              </Link> */}
              {donateButton}
            </div>
            <div className="bg-donasi">
              {/* <Link
                to={"/donateform/" + pls}
                style={{ textAlign: "center" }}
                className="button bg-highlight button-m button-full button-round-medium shadow-small"
              >
                DONASI SEKARANG
              </Link> */}
              {donateButton}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default DetailProgram;
