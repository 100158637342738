import React, { Component } from 'react'
import {
    Redirect,
    Link
} from 'react-router-dom'
import { EMAIL_VERIFY } from '../Constant'
import axios from 'axios'
import Cookies from 'universal-cookie';
import Header from '../Components/Header';

const cookies = new Cookies();

class VerifyEmail extends Component {
    constructor() {
        super()
        this.state = {
            go_login: false
        }
    }

    componentDidMount() {
        this.verify()
    }

    verify() {
        let { match: { params } } = this.props

        axios.post(EMAIL_VERIFY + '/' + params.token).
            then(response => {
                alert(response.data.message)
                if (response.data.status == 200) {
                    this.setState({ go_login: true })
                }
            }).catch(error => {
                console.log(error)
            })
    }

    render() {
        const {
            go_login
        } = this.state

        if (go_login)
            return <Redirect exact to="/login" />

        return (
            <div className="page-content">
                <Header />
                <div className="content-boxed">
                    <section id="donasi-box">
                        <center><label style={{ fontWeight: 'bold', fontSize: '22px', color: '#FFD52F' }}>Verifikasi Email ...</label></center>
                        <img src="/invite-cuate.svg" />
                   </section>
                </div>
            </div>
        )
    }
}

export default VerifyEmail