import React, { Component } from 'react'
import {
    Link
} from "react-router-dom"
import Cookies from 'universal-cookie'
import { Home, FileText, PhoneCall, Book, Menu, User, LogIn, LogOut, List, UserPlus } from 'react-feather'
import * as Constant from '../Constant'
import Modal from 'react-modal';
const customStyles = {
    content: {
        top: '10px',
        bottom: '10px',
        right: '0px',
        width: '260px',
        overflow: 'scroll',
        border: '0px',
        borderRadius: '15px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        zIndex: 100
    },
    overlay: {
        zIndex: 99,
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
    }
};
Modal.setAppElement('#root');


const cookies = new Cookies();
class Footer extends Component {
    constructor() {
        super()
        this.state = {
            id: '',
            gone: '',
            setIsOpen: false
        }
    }

    componentDidMount() {
        // this.ifLogin()
        // console.log('hei', window.location.href.split("/"));
        let user_id;
        if (cookies.get('user_id') != null) {
            user_id = cookies.get('user_id')
        } else {
            user_id = null;
        }


    }
    logOut() {
        cookies.remove('user_id');
        cookies.set('is_guest', true);
        window.location.href = "/login"
    }

    render() {
        let endpoint = window.location.href.split("/")[3]
        let user_id;
        if (cookies.get('user_id') != null) {
            user_id = cookies.get('user_id')
        } else {
            user_id = null;
        }


        return (
            <>
                {/* FOOTER */}
                {/* <footer style={{margin: 10+'px', padding: 10+'px'}}>
                <center><p>Copyrights © 2020 All Rights Reserved by Teman Peduli.</p></center>
            </footer> */}<br /><br />
                <div id="footer-menu" className="footer-menu-5-icons footer-menu-style-3">
                    <div className="res">
                        <table style={{ marginBottom: '3px' }}>
                            <tbody>
                                <tr>
                                    <td className="td-table" style={{ marginLeft: '25%' }}>
                                        <div>
                                            {endpoint !== '' ?
                                                <Link to={'/'} style={{ width: '100%' }}>
                                                    <Home stroke={endpoint == '' ? "#ea6123" : '#AAB2BD'} size={30} />
                                                    {/* Jika route tidak sama dengan endpoint, maka user dapat klik tombol ini untuk pindah halaman */}
                                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == '' ? "#ea6123" : "#AAB2BD" }}>Beranda</label>
                                                </Link>
                                                :
                                                <div style={{ width: '100%' }}>
                                                    <Home stroke={endpoint == '' ? "#ea6123" : '#AAB2BD'} size={30} />
                                                    {/* Jika route tidak sama dengan endpoint, maka user dapat klik tombol ini untuk pindah halaman */}
                                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == '' ? "#ea6123" : "#AAB2BD" }}>Beranda</label>
                                                </div>
                                            }

                                        </div>
                                    </td>
                                    <td className="td-table" style={{ marginLeft: '25%' }}>
                                        <div>
                                            {endpoint !== "programs" ?
                                                <Link to={'/programs'} style={{ width: '100%' }}>
                                                    {/* Jika route tidak sama dengan endpoint, maka user dapat klik tombol ini untuk pindah halaman */}
                                                    <FileText stroke={endpoint == 'programs' ? "#ea6123" : '#AAB2BD'} size={30} />
                                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == 'programs' ? "#ea6123" : "#AAB2BD" }}>Program</label>
                                                </Link>
                                                :
                                                <div style={{ width: '100%' }}>
                                                    {/* Jika route tidak sama dengan endpoint, maka user dapat klik tombol ini untuk pindah halaman */}
                                                    <FileText stroke={endpoint == 'programs' ? "#ea6123" : '#AAB2BD'} size={30} />
                                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == 'programs' ? "#ea6123" : "#AAB2BD" }}>Program</label>
                                                </div>
                                            }
                                        </div>
                                    </td>
                                    <td className="td-table" style={{ marginLeft: '25%' }}>
                                        <div>
                                            {endpoint !== "update" ?
                                                <Link to={'/update'} style={{ width: '100%' }}>
                                                    <Book stroke={endpoint == 'update' ? "#ea6123" : '#AAB2BD'} size={30} />
                                                    {/* Jika route tidak sama dengan endpoint, maka user dapat klik tombol ini untuk pindah halaman */}
                                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == 'update' ? "#ea6123" : "#AAB2BD" }}>Postingan</label>
                                                </Link>
                                                :
                                                <div style={{ width: '100%' }}>
                                                    <Book stroke={endpoint == 'update' ? "#ea6123" : '#AAB2BD'} size={30} />
                                                    {/* Jika route tidak sama dengan endpoint, maka user dapat klik tombol ini untuk pindah halaman */}
                                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == 'update' ? "#ea6123" : "#AAB2BD" }}>Postingan</label>
                                                </div>
                                            }
                                        </div>
                                    </td>
                                    <td className="td-table" style={{ marginLeft: '25%' }}>
                                        <div className="item-menu-footer" style={{ cursor: 'pointer' }}>
                                            <div className="item-menu-footer" style={{ width: '100%' }} onClick={() => this.setState({ setIsOpen: true })}>
                                                <Menu stroke={'#AAB2BD'} size={30} />
                                                <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: "#AAB2BD" }}>Menu</label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="clear"></div>
                    </div>

                </div>


                <Modal
                    isOpen={this.state.setIsOpen}
                    onRequestClose={() => this.setState({ setIsOpen: false })}
                    contentLabel="Example Modal"
                    style={customStyles}
                    className="menu menu-box-right round-medium res"
                    closeTimeoutMS={300}>
                    <div className="res">
                        <div className="filter">
                            <div>
                                <h3 style={{ marginBottom: 30 + 'px' }}>Menu</h3>
                                <div>
                                    {user_id != null &&
                                        <Link to="/profile" style={{ width: '100%' }}>
                                            <div className="item-menu" style={{marginBottom: "10px"}}>
                                                <User stroke={'#AAB2BD'} size={25} className="ic-menu" style={{verticalAlign: "bottom", marginRight: "10px"}} />
                                                Profile
                                            </div>
                                        </Link>
                                    }
                                    {user_id != null &&
                                        <Link to="/riwayat_donasi" style={{ width: '100%' }}>
                                            <div className="item-menu" style={{marginBottom: "10px"}}>
                                                <List stroke={'#AAB2BD'} size={25} className="ic-menu" style={{verticalAlign: "bottom", marginRight: "10px"}} />
                                                RiwayatDonasi
                                            </div>
                                        </Link>
                                    }
                                    <Link to="/hubungi" style={{ width: '100%' }}>
                                        <div className="item-menu" style={{marginBottom: "10px"}}>
                                            <PhoneCall stroke={'#AAB2BD'} size={25} className="ic-menu" style={{verticalAlign: "bottom", marginRight: "10px"}} />
                                            Hubungi Kami
                                        </div>
                                    </Link>
                                    {user_id == null &&
                                        <Link to="/login" style={{ width: '100%' }}>
                                            <div className="item-menu" style={{marginBottom: "10px"}}>
                                                <LogIn stroke={'#AAB2BD'} size={25} className="ic-menu" style={{verticalAlign: "bottom", marginRight: "10px"}} />
                                                Login
                                            </div>
                                        </Link>
                                    }
                                    {user_id == null &&
                                        <Link to="/daftar" style={{ width: '100%' }}>
                                            <div className="item-menu" style={{marginBottom: "10px"}}>
                                                <UserPlus stroke={'#AAB2BD'} size={25} className="ic-menu" style={{verticalAlign: "bottom", marginRight: "10px"}} />
                                                Daftar
                                            </div>
                                        </Link>
                                    }
                                    {user_id != null &&
                                        <div className="item-menu" style={{marginBottom: "10px"}}>
                                            <LogOut stroke={'#AAB2BD'} size={25} className="ic-menu" style={{verticalAlign: "bottom", marginRight: "10px"}} onClick={() => this.logOut()} />
                                            Log Out
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>


            </>
        )
    }
}


export default Footer