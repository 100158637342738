import * as firebase from 'firebase'
import axios from 'axios';
import Cookies from 'universal-cookie';
import * as Constant from './Constant';

const cookies = new Cookies();
const serverKey = "AAAA_B6ZYJo:APA91bF4iBSwBFj2AXU_0mx_CC93ekNv0pyGb_cT45XUoiqsd6wp_3bjKK9tnyxoCI5_dpAaGf52QzEf3RtiXUV34lmdfuSCRbbLqum5NlWtGdefqmp8k6HrvrKmwogX9rx_gaDdpCOo";
export const initializeFirebase = () => {
  const config = {
    apiKey: "AIzaSyAQyyL0jN16IB09IkIoX3I69CbTqgvThAc",
    authDomain: "crowdfunding-pwa.firebaseapp.com",
    databaseURL: "https://crowdfunding-pwa.firebaseio.com",
    projectId: "crowdfunding-pwa",
    storageBucket: "crowdfunding-pwa.appspot.com",
    messagingSenderId: "1082845126810",
    appId: "1:1082845126810:web:2408f23bae100658acff23"
  }
  firebase.initializeApp(config)

  // navigator.serviceWorker
  //     .register('/firebase-messaging-sw.js')
  //     .then((registration) => {
  //         firebase.messaging().useServiceWorker(registration);
  //     });
}

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const key_yayasan = Constant.KEY_YAYASAN;
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    cookies.set('fcmToken', token)

    const body = {}
    axios.post('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/'+key_yayasan, body, {
      headers: {
        'Authorization': 'key=' + serverKey
      }
    })
      .catch(error => {
        console.log(error)
      })

    return token;
  } catch (error) {
    console.error(error);
  }
}
