import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import * as Constant from '../Constant'
import axios from 'axios'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Qurban extends Component {
    constructor() {
        super()
        this.state = {
            user_id: null,
            full_name: '',
            email: '',
            phone_number: '',
            address: '',
            pequrban_name: '',
            sedekahPemotongan: 0,

            products: [],
            product: '',
            product_id: 0,
            total: 0,
            nama_prodak: null,

            go_home: false,
            go_paid: false,

            payment_method: 0,
            vendor: [],
            
            phone: '',
            card_number: '',
            exp_month: '',
            exp_year: '',
            cvn: '',
            alfamart_code: 'ALFAMARTCODEHERE!!!',
            
            go_payment: false,
            checkout_url: '',
            go_via_checkout: false,

            snk: []
        }
    }

    componentDidMount() {
        this.getQurbanProducts()
        this.setUserIfLogin()
        this.getSyaratDanKetentuan()

        if(cookies.get('selected_method')){
            this.getPaymentMethod()
        }else{
            document.getElementById('logo_payment').hidden = true
            document.getElementById('label_phone').hidden = true
            document.getElementById('card_number').hidden = true
            document.getElementById('exp_month').hidden = true
            document.getElementById('exp_year').hidden = true
            document.getElementById('cvn').hidden = true
        }
    }

    getQurbanProducts() {
        axios.get(Constant.GET_QURBAN_PRODUCTS)
            .then(response => {
                // console.log(response.data)
                this.setState({ 
                    products: response.data.data
                });
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    setUserIfLogin(){
        if(cookies.get('user_id') != null){
            axios.get(Constant.GET_USER, {
                headers: {
                    'Authorization': cookies.get('accessToken')
                }
            }).then(response => {
                this.setState({
                    user_id: response.data.id,
                    full_name: response.data.name,
                    phone_number: response.data.no_telp,
                    email: response.data.email
                });

                document.getElementById('full_name').readOnly = true
                // document.getElementById('phone_number').readOnly = true
                document.getElementById('email').readOnly = true
                document.getElementById('btn_for_login').hidden = true

            }).catch(error => {
                console.log(error)
            })
        }
    }
    getSyaratDanKetentuan(){
        axios.get(Constant.GET_SYARAT_DAN_KETENTUAN)
        .then(response => {
            this.setState({
                snk: response.data
            })
            console.log(this.state.snk)
        }).catch(error => {
            console.log(error)
        })
    }
    
    getPaymentMethod(){
        let selected_method = cookies.get('selected_method')
        if(selected_method != null || selected_method != ''){
            cookies.remove('linkBefore')

            if(cookies.get('customerBio')){
                this.setState({
                    user_id: cookies.get('customerBio').user_id,
                    full_name: cookies.get('customerBio').full_name,
                    phone_number: cookies.get('customerBio').phone_number,
                    email: cookies.get('customerBio').email,
                    address: cookies.get('customerBio').address,
                    pequrban_name: cookies.get('customerBio').pequrban_name,
                    product_id: cookies.get('customerBio').product_id,
                    sedekahPemotongan: cookies.get('customerBio').sedekahPemotongan,
                    total: cookies.get('customerBio').total,

                    payment_method: cookies.get('selected_method'),
                    vendor: cookies.get('vendor')
                })
            }
            
            if(cookies.get('vendor').payment_name == "OVO" || cookies.get('vendor').payment_name == "LINKAJA"){
                document.getElementById('label_phone').hidden = false
                document.getElementById('phone').hidden = false
            }else{
                document.getElementById('label_phone').hidden = true
                document.getElementById('phone').hidden = true
            }

            if(cookies.get('vendor').payment_name == "Visa, Mastercard, JCB"){
                document.getElementById('card_number').hidden = false
                document.getElementById('exp_month').hidden = false
                document.getElementById('exp_year').hidden = false
                document.getElementById('cvn').hidden = false
            }else{
                document.getElementById('card_number').hidden = true
                document.getElementById('exp_month').hidden = true
                document.getElementById('exp_year').hidden = true
                document.getElementById('cvn').hidden = true
            }
        }
    }

    handleInputChange(e) {
        const { name, value } = e.target
        if (name == "product") {
            
            let harga = (e.target.selectedIndex > 0) 
                            ? this.state.products[e.target.selectedIndex-1].nominal
                            : 0
            this.setState({
                [name]: e.target.options[e.target.selectedIndex].text,
                [name+"_id"]: value,
                total: harga,
                nama_prodak: this.state.products[e.target.selectedIndex-1].name
            })
        } else {
            this.setState({ [name]: value})
        }
    }
    goToPayment(){
        const { 
            zakat, user_id, full_name, phone_number, email, address, pequrban_name, product_id, sedekahPemotongan, total
        } = this.state

        console.log(total)

        let url = this.props.location.pathname
        let array = {
            'user_id': user_id,
            'full_name': full_name,
            'phone_number': phone_number,
            'email': email,
            'address': address,
            'pequrban_name': pequrban_name,
            'product_id': product_id,
            'sedekahPemotongan': sedekahPemotongan,
            'total': total,
        }

        cookies.set('linkBefore', url);
        cookies.set('customerBio', array);
        this.setState({ go_payment: true })
    }

    doQurban() {
        const {
            full_name, email, phone_number,
            address, pequrban_name, product,
            product_id, total, user_id, vendor, phone
        } = this.state

        if(full_name == "" || full_name == null){
            alert('Nama Lengkap Tidak Boleh Kosong')
        }else if(email == "" || email == null){
            alert('Email Tidak Boleh Kosong')
        }else if(phone_number == "" || phone_number == null){
            alert('Nomor Telepon Tidak Boleh Kosong')
        }else if(address == "" || address == null){
            alert('Alamat Tidak Boleh Kosong')
        }else if(pequrban_name == "" || pequrban_name == null){
            alert('Nama Pequrban Tidak Boleh Kosong')
        }else if(product_id == "" || product_id == null){
            alert('Harap Pilih Produk Qurban')
        }else if(/(.+)@(.+){2,}\.(.+){2,}/.test(email)){
            let vendor_name = vendor.payment_name
            if(vendor_name == "OVO"){
                if(phone == null || phone == ""){
                    alert('Harap Isi Nomor Telepon E-Wallet Anda')
                }else{
                    document.getElementById("ovo_popup").style.display = "block"
                    this.sendTransaction()
                }
            }else{
                this.sendTransaction()
            }
        }else{
            alert('Email Tidak Valid')
        }
    }
    sendTransaction(){
        const { 
            user_id, category, vendor, sedekahPemotongan, phone, total, product, product_id,
            full_name, phone_number, email, pequrban_name, address,
            card_number, exp_month, exp_year, cvn
        } = this.state

        let nomor_hp = null
        if(vendor.payment_name == 'OVO' || vendor.payment_name == 'DANA'){
            nomor_hp = phone
        }else{
            nomor_hp = phone_number
        }
        let metode_pembayaran = null
        if(vendor.payment_type == 'virtual_account'){
            metode_pembayaran = vendor.midtrans_code
        }else if(vendor.payment_name == "Alfamart"){
            metode_pembayaran = vendor.payment_name.toUpperCase()
        }else if(vendor.payment_name == "GOPAY"){
            metode_pembayaran = vendor.payment_name.toLowerCase()
        }else{
            metode_pembayaran = vendor.payment_name
        }

        let array = []
        if(vendor.payment_type == 'credit_card'){
            array = {
                'account_number': card_number,
                'exp_month': exp_month,
                'exp_year': exp_year,
                'cvn': cvn
            }
        }

        const parameter = {
            'total_qurban': total+sedekahPemotongan,
            'nama_produk': product,
            'nama': full_name,
            'email': email,
            'no_telp': phone_number,
            'user_id': user_id,
            'vendor': vendor.vendor,
            'payment_type': vendor.payment_type,
            'payment_method': metode_pembayaran,
            'card_data': array,
            'phone': nomor_hp,
            'device': 'PWA',
            'product_id': product_id,
            'nama_pequrban': pequrban_name,
            'alamat_pequrban': address,
            'harga_qurban': total,
            'sedekah_qurban': sedekahPemotongan
        }
        console.log(parameter, category)
        axios.post(Constant.XENDIT_SNAPTOKEN_QURBAN, parameter)
            .then(response => {
                console.log(response)
                if(response.data.snap_token != null){
                    cookies.remove('linkBefore')
                    cookies.remove('customerBio')
                    cookies.remove('selected_method')
                    cookies.remove('vendor')
                    console.log(response)
                    window.snap.pay(response.data.snap_token, {
                        // Optional
                        onSuccess: function(result){
                            let pisah_url = window.location.href.split('/')
                            window.location = pisah_url[0]+'//'+pisah_url[2]+'/notif/notif-wait'
                        },
                        // Optional
                        onPending: function(result){
                            let pisah_url = window.location.href.split('/')
                            window.location = pisah_url[0]+'//'+pisah_url[2]+'/notif/notif-wait'
                        },
                        // Optional
                        onError: function(result){
                            console.log("gagal")
                        },
                        onClose: function(result){
                            console.log(response.data)
                            let id = ''
                            if(vendor.vendor == "midtrans"){
                                id = response.data.snap_token
                            }
                            const data_send = {
                                iDtoken: id,
                                status: 1
                            }
                            axios.post(Constant.DELETE_SNAPTOKEN, data_send)
                            .then(response => {
                                alert("Transaksi Dibatalkan")
                            })
                            .catch(error => {
                                console.log(error)
                            })
                        }
                    })
                }else{
                    console.log(response)
                    if(response.data.data_ew){
                        window.location.href = response.data.data_ew.checkout_url
                    }
                    if(response.data.data_ro){
                        this.setState({
                            alfamart_code: response.data.data_ro.payment_code
                        })
                        console.log(this.state.alfamart_code)
                        document.getElementById("alfamart_popup").style.display = "block"
                    }
                }
            })
    }

    updateDeviceTypeTransaction(id) {
        let parameter = {
            id: id,
            transaksi_id: "Q"+id,
            device: "PWA"
        }
        axios.post(Constant.UPDATE_DEVICE_AFTER_TRANSACTION, parameter, {
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            .then(response => {
                // console.log(response.data)
                
                this.setState({ go_paid: true })
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    onAmountChanged(e) {
        let { name, value } = e.target
        this.setState({ [name]: parseInt(value.split('.').join('')) })
    }

    render() {
        const {
            full_name, email, phone_number,
            address, pequrban_name, products, sedekahPemotongan,
            product_id, total, go_home, go_paid, go_payment,
            vendor, alfamart_code, phone, card_number, exp_month, exp_year, cvn, snk
        } = this.state

        let foto = ''

        if(vendor){
            foto = Constant.GET_PICT_VENDOR+vendor.id
        }

        if (go_home)
            return <Redirect exact to="/home" />
        else if(go_paid)
            return <Redirect exact to="/notif/notif-wait" />
        else if(go_payment)
            return <Redirect exact to="/payment_method" />

        return(
        <div>
			<header id="header">
				<nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({go_home:true})}>
                    </i>
                    Tunaikan Qurban
				</nav>
			</header>

            {/* OVO */}
            <div id="ovo_popup" className="modalfilter">
                <div className="filter-content">
                    <span className="close" onClick={() => document.getElementById("ovo_popup").style.display = "none"}>
                        &times;
                    </span>

                    <p className="head-filter">OVO</p>
                    <p className="all-category">
                        <img src={foto} style={{maxWidth: 150+'px'}}/>
                    </p>
                    <div className="scroller-category" style={{height: 67+'vh'}}>
                        <center>
                            <b>Instruksi Pembayaran</b>
                            <br/>
                            Nominal Yang Akan Didonasikan : 
                            <br/>
                            <h2 >Rp. {this.numberFormat(total + sedekahPemotongan)}</h2>
                            <br/>
                            Periksa aplikasi OVO anda dan segera selesaikan pembayaran dalam 30 detik!
                        </center>
                        <b>Notes : </b>
                        <br/>
                        Apabila transaksi gagal, silahkan coba cara berikut : 
                        <br/>
                        1. Masukan kembali nomor telepon dan coba kembali pembayaran.
                        <br/>
                        2. Bersihkan cache aplikasi OVO anda.
                        <br/>
                        3. Apabila transaksi masih gagal, silahkan coba kembali dengan metode pembayaran yang lain. Anda TIDAK akan dikenakan biaya dua kali untuk mencoba kembali pembayaran
                        <br/>
                        <br/>
                        <a className="btn-dns" style={{padding: 10+"px", position: 'absolute', bottom: 66+'px', width: 85+'%', marginLeft: 10+'px'}} onClick={() => this.setState({go_paid: true})}><p>Saya Sudah Bayar</p></a>
                    </div>
                </div>
            </div>

            {/* ALFAMART */}
            <div id="alfamart_popup" className="modalfilter">
                <div className="filter-content">
                    <span className="close" onClick={() => document.getElementById("alfamart_popup").style.display = "none"}>
                        &times;
                    </span>

                    <p className="head-filter">ALFAMART</p>
                    <p className="all-category">
                        <img src={foto} style={{maxWidth: 150+'px'}}/>
                    </p>
                    <div className="scroller-category">
                        <center>
                            <b>Instruksi Pembayaran</b>
                            <br/>
                            Nominal Yang Akan Didonasikan : 
                            <br/>
                            <h2 style={{marginBottom: 0+'px'}}>Rp. {this.numberFormat(total + sedekahPemotongan)}</h2>
                            <br/>
                            <h3 style={{marginBottom: 0+'px'}}>{alfamart_code}</h3>
                            <br/>
                            Harap simpan kode pembayaran di atas untuk melakukan pembayaran!
                        </center>
                        <a className="btn-dns" style={{padding: 10+"px", position: 'absolute', bottom: 125+'px', width: 85+'%', marginLeft: 10+'px'}} onClick={() => this.setState({go_paid: true})}><p>Selesaikan Pembayaran</p></a>
                    </div>
                </div>
            </div>

            {/* SnK */}
            <div id="syaratDanKetentuan" className="modalfilter">
                <div className="filter-content" style={{height: 90+'vh'}}>
                    <span className="close" onClick={() => document.getElementById("syaratDanKetentuan").style.display = "none"}>
                        &times;
                    </span>

                    <p className="head-filter">Syarat Dan Ketentuan</p>
                    
                    <div className="scroller-category" style={{height: 70+'vh'}}>
                        <center>
                        { ReactHtmlParser(snk.konten_page) }
                        </center>
                        <a className="btn-dns" style={{padding: 10+"px", position: 'absolute', bottom: 58+'px', width: 85+'%', marginLeft: 10+'px'}} onClick={() => document.getElementById("syaratDanKetentuan").style.display = "none"}><p>S E T U J U</p></a>
                    </div>
                </div>
            </div>
            
			<section id="donasi-box">
                <center><p className="title-donasi">Tunaikan Qurban</p></center>
                
                <div id="btn_for_login">
                    <br/>
                    <center>
                        <a className="red-text" href="/login">
                            Silahkan Login atau isi data di bawah ini
                        </a>
                    </center>
                </div>

                <p className="label-form">Nama Lengkap</p>
                <input 
                    type="text" 
                    name="full_name" 
                    id="full_name" 
                    className="input-form" 
                    value={full_name}
                    onChange={(e) => this.handleInputChange(e)}
                />

                <p className="label-form">Email</p>
                <input 
                    type="email" 
                    name="email" 
                    id="email" 
                    className="input-form" 
                    value={email}
                    onChange={(e) => this.handleInputChange(e)}
                />

                <p className="label-form">No Handphone / Whatsapp</p>
                <input 
                    type="number" 
                    name="phone_number" 
                    id="phone_number" 
                    className="input-form" 
                    value={phone_number}
                    onChange={(e) => this.handleInputChange(e)}
                />

                <p className="label-form">Alamat</p>
                <input 
                    type="text" 
                    name="address" 
                    id="address" 
                    className="input-form" 
                    value={address}
                    onChange={(e) => this.handleInputChange(e)}
                />

                <p className="label-form">Nama Pequrban</p>
                <textarea name="pequrban_name" 
                    id="pequrban_name" 
                    rows="5"
                    value={pequrban_name}
                    onChange={(e) => this.handleInputChange(e)}>

                </textarea>

                <br />
                <table style={{marginBottom:0+'px'}} id="logo_payment">
                    <tbody>
                        <tr>
                            <td width="30%">
                                <img src={foto} style={{maxWidth: 80+'px'}}/>
                            </td>
                            <td style={{verticalAlign: 'text-top'}}>
                                {(vendor) ? vendor.payment_name : ''}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <a className="btn-dns" id="btn_choose_method" style={{padding: 10+"px"}} onClick={() => this.goToPayment()}><p>Pilih Metode Pembayaran</p></a>

                <p className="label-form" id="label_phone">Nomor Telepon E-Wallet</p>
                <input type="number" name="phone" id="phone" className="input-form" value={phone} onChange={(e) => this.handleInputChange(e)} />
                
                {/* credit_card */}
                {/* <p className="label-form" id="label_phone"></p> */}
                <input type="number" name="card_number" placeholder="Card Number" id="card_number" className="input-form" value={card_number} onChange={(e) => this.handleInputChange(e)} />
                
                {/* <p className="label-form" id="label_phone"></p> */}
                <input type="number" name="exp_month" placeholder="Expired Month" id="exp_month" className="input-form" max="12" value={exp_month} onChange={(e) => this.handleInputChange(e)} />
                
                {/* <p className="label-form" id="label_phone"></p> */}
                <input type="number" name="exp_year" placeholder="Expired Year" id="exp_year" className="input-form" max="2100" value={exp_year} onChange={(e) => this.handleInputChange(e)} />
                
                {/* <p className="label-form" id="label_phone"></p> */}
                <input type="number" name="cvn" placeholder="CVN" id="cvn" className="input-form" value={cvn} onChange={(e) => this.handleInputChange(e)} />
                {/* end credit_card */}

                <p className="label-form">Hewan Qurban</p>
                <form>
                    <select 
                        name="product" 
                        id="product" 
                        className="input-form"
                        value={product_id}
                        onChange={(e) => this.handleInputChange(e)}
                    >
                        <option value="0" key="-1">Pilih Hewan Qurban</option>
                        {products.map((product, i) => {
                            return(
                                <option value={product.id} key={i}>
                                    {product.name}
                                </option>
                            )
                        })}
                    </select>

                    <p className="label-form">Harga</p>
                    <input 
                        type="text" 
                        name="total" 
                        id="total" 
                        className="input-form readonly" 
                        value={this.numberFormat(total)}
                        readonly 
                    />

                    <p className="label-form">Sedekah pemotongan dan distribusi</p>
                    <input 
                        type="text" 
                        name="sedekahPemotongan" 
                        id="sedekahPemotongan" 
                        className="input-form" 
                        value={this.numberFormat((sedekahPemotongan)?sedekahPemotongan:0)} 
                        onChange={(e) => this.onAmountChanged(e)}
                    />
                    <br/>

                    <p style={{color: '#000', fontSize: 13+'px'}}>
                        Dengan berdonasi kurban di Teman Peduli anda telah menyetujui syarat dan ketentuan.
                        <a onClick={() => document.getElementById("syaratDanKetentuan").style.display = "block"} style={{textDecoration: 'none', color: '#ff0000'}}> Baca syarat dan ketentuan</a>
                    </p>

                    <br/><br/>
                    
                    <a 
                        className="btn-dns" 
                        style={{padding: "10px"}}
                        onClick={() => {this.doQurban()}}
                    >
                        <p>Tunaikan Qurban </p>
                    </a>
                </form>
                <br/><br/>
               
            </section>
        </div>
        )
    }
}

export default Qurban