import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../../Constant'
import axios from 'axios'
import Cookies from 'universal-cookie';
// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #3DADAA;
`;

const cookies = new Cookies();

class Notification extends Component {
    constructor() {
        super()
        this.state = {
            page:1 ,
            last_page: 1,
            notif: [],
            loading: true,

            go_home: false
        }

        this.timer = null
        this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {
        this.getNotif()

        document.addEventListener('scroll', this.trackScrolling)
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)
    }
    trackScrolling() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.getElementById("notifs_scroller")
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        if (windowBottom >= docHeight  && 
            this.state.page != this.state.last_page) 
        {
            // console.log(windowBottom + " == " + docHeight)
            // reach to bottom
            let next = this.state.page + 1
            this.setState({ 
                page: next,
                loading: true 
            })
            this.getNotif()
            console.log('there it is')
        }
    }
    getNotif() {
        const { 
            page
        } = this.state
        let query_url = `?page=${page}`

        axios.get(Constant.GET_NOTIFICATION + query_url, {
            headers: {
                'Authorization': cookies.get('accessToken')
            }
        })
        .then(response => {
            if (page == 1) {
                this.setState({ 
                    notif: response.data.data.data,
                    last_page: response.data.last_page,
                    loading: false
                })
            } else {
                let newUpdates = this.state.notif.concat(response.data.data.data)
                this.setState({ 
                    notif: newUpdates,
                    last_page: response.data.last_page,
                    loading: false
                })
            }
        })
        // Catch any error here
        .catch(error => {
            console.log(error)
        })
    }

    readNotif(id){
        axios.get(Constant.READ_NOTIFICATION + id, {
            headers: {
                'Authorization': cookies.get('accessToken')
            }
        }).then(response => {
            console.log(response)
        }).catch(error => {
            console.log(error)
        })
    }

    render() {
        const {
            notif, go_home
        } = this.state

        console.log(notif)

        if (go_home)
            return <Redirect exact to="/home" />

        return(
        <div>
			<header id="header">
				<nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({go_home:true})}>
                    </i>
                    Notification
				</nav>
			</header>
            
			<section id="riwayat-qurban-box">
                <div className="scroller-program" id="notifs_scroller">
                    {notif.map((notif, i) => {

                        return(
                            <a href={notif.notif_related.link} target="_BLANK" style={{color: '#767D85'}} onClick={() => this.readNotif(notif.id)}>
                                <div className="card-program" key={i}>
                                    <table className="tbl-qurban" style={{marginBottom: 0}}>
                                        <tbody>
                                            <tr>
                                                <td className="first-td">{notif.notif_related.judul}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </a>
                        )
                    })}
                </div>
                <div className="sweet-loading" style={{height: 50+'px'}}>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={"#3DADAA"}
                        loading={this.state.loading}
                    />
                </div>
            </section>
        </div>
        )
    }
}
export default Notification