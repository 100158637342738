import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';

class Mitra extends Component {
  render() {
    return (
      <>
        <div className="content bottom-10 top-30">
          <h5 className="float-left font-600">Mitra Perusahaan</h5>
          <div className="clear"></div>
        </div>
        <OwlCarousel items={2}
          loop
          autoplay
          autoplaySpeed={5}
          dots={false}
          stagePadding={30}
          lazyLoad={true}
          margin={10}
          animateIn="animate-in"
          animateOut="animate-out">
          <div style={{height: 200+"px"}} className="item bg-theme round-small shadow-small center-text bottom-30">
            <img style={{marginTop: 25+'px'}} src="/ywci/mitra/1.jpg" />
          </div>
          <div style={{height: 200+"px"}} className="item bg-theme round-small shadow-small center-text bottom-30">
            <img style={{marginTop: 25+'px'}} src="/ywci/mitra/2.jpg" />
          </div>
          <div style={{height: 200+"px"}} className="item bg-theme round-small shadow-small center-text bottom-30">
            <img src="/ywci/mitra/3.jpg" />
          </div>
          <div style={{height: 200+"px"}} className="item bg-theme round-small shadow-small center-text bottom-30">
            <img src="/ywci/mitra/4.jpg" />
          </div>
          <div style={{height: 200+"px"}} className="item bg-theme round-small shadow-small center-text bottom-30">
            <img src="/ywci/mitra/5.jpg" />
          </div>
          <div style={{height: 200+"px"}} className="item bg-theme round-small shadow-small center-text bottom-30">
            <img src="/ywci/mitra/6.jpg" />
          </div>
        </OwlCarousel>
      </>
    )
  };
}

export default Mitra;