import React, { Component } from 'react'
import {
    Redirect,
    Link
} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import * as Constant from '../../Constant'
import Header from '../../Components/Header'
import axios from 'axios'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';


const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div style={{
            width: '100%',
            height: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px'
        }}>
            <Loader type="Oval" color={'#ea6123'} height="80" width="80" />
        </div>
    )
}

class DetailUpdate extends Component {
    constructor() {
        super()
        this.state = {
            update: {},
            image_link: '',

            target: 0,
            collected: 0,
            percentage: 0,
            final_day: Date.now(),
            donatur: 0,

            go_back: false,
            go_donate: false,
            update_loading: true,

            user_ip: '',
        }
    }

    componentDidMount() {
        // get user IP address
        axios
          .get("https://api.ipify.org/?format=json")
          .then((res) => {
            this.setState({
              user_ip: res.data.ip,
            });
    
            this.getUpdateDetail()
          });

        document.body.style.backgroundImage = "none"
    }


    getUpdateDetail() {
        let { match: { params } } = this.props
        const { user_ip } = this.state;

        trackPromise(
            axios.get(Constant.GET_UPDATE_DETAIL + params.seoKey, {
                    headers: {
                        'X-USR-IP': user_ip
                    }
                })
                .then(response => {
                    console.log(response.data.data.content)
                    let update = response.data.data
                    this.setState({
                        update: update,
                        image_link: Constant.GET_UPDATES_IMAGE + update.id_posting,
                        update_loading: false
                    });
                    
                })
                // Catch any error here
                .catch(error => {
                    console.log(error)
                })
        )
    }
    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }

    dateFormat(date) {
        let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu'];
        let months = [
            'Januari', 'Februari', 'Maret',
            'April', 'Mei', 'Juni', 'Juli',
            'Agustus', 'September', 'Oktober',
            'November', 'Desember'
        ]

        let new_date = new Date(date)
        let formatted_date = days[new_date.getDay()] + ", "
            + new_date.getDate() + " "
            + months[new_date.getMonth()] + " "
            + new_date.getFullYear()

        return formatted_date
    }

    newTabClickedF() {
        let linkFacebook = window.open(Constant.LINK_FACEBOOK, '_blank')
        linkFacebook.focus()
    }

    newTabClickedW() {
        let linkWhatsapp = window.open(Constant.LINK_WA, '_blank')
        linkWhatsapp.focus()
    }

    render() {
        const { match, match: { params } } = this.props
        const {
            update, image_link, go_back, go_donate,
            target, collected, percentage, final_day, donatur,
            update_loading
        } = this.state

        let linkFacebook = "https://www.facebook.com/sharer/sharer.php?u=https://mizanamanah.or.id/update/" + update.seo
        let linkTwitter = "http://twitter.com/intent/tweet?url=" + update.judul + " Klik Link Berikut https://mizanamanah.or.id/update/" + update.seo
        let linkEmail = "mailto:?Subject=&Body=" + update.judul + " Klik Link Berikut https://mizanamanah.or.id/update/" + update.seo
        let linkWhatsapp = "https://api.whatsapp.com/send?text=" + update.judul + " Klik Link Berikut https://mizanamanah.or.id/update/" + update.seo

        return (
            <div className="page-content">
                <div className="header header-fixed header-logo-app header-auto-show">
                    <div className="res">
                        <Link to={'/update'}>
                            <i id="ic-sidebar" className="fa fa-arrow-left" style={{ padding: 20 + "px" }}></i>
                            {String(update.judul).substring(0, 32)}...
                        </Link>
                    </div>
                </div>
                <Header back_url="/update" />
                <div className="content">
                    <div className="content-boxed" style={{ marginTop: 0 }}>
                        <div className="content">
                            <LoadingIndicator />
                            {update_loading == false ?
                                <>
                                    <img src={image_link} className="caption-image owl-lazy" style={{ borderRadius: '13px' }} /><br />
                                    <h3 style={{ lineHeight: '1.2em' }}>{update.judul}</h3>

                                    <br />


                                    <div className="content-detail">
                                        {ReactHtmlParser(update.content)}
                                    </div>

                                    <div className="content-detail">
                                        <hr />
                                        Mari berbagi informasi yang insha Allah bermanfaat kepada rekan dan saudara kita. Semoga menjadi amal soleh yang membawa keberkahan untuk anda. klik tombol share di bawah ini.

                                        <table>
                                            <thead>
                                                <tr>
                                                    <td>
                                                        <div className="float-right">
                                                            <a style={{ marginRight: 10 + 'px' }} href="#" onClick={() => this.newTabClickedW()}>
                                                                <img src="/wa2.png" style={{ maxWidth: '50px' }} />
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="float-left">
                                                            <a style={{ marginLeft: 10 + 'px' }} href="#" onClick={() => this.newTabClickedF()}>
                                                                <img src="/facebook.png" style={{ maxWidth: '50px' }} />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </>
                                :
                                <div>
                                    <center>
                                        <label style={{ marginTop: '10px', color: '#ea6123' }}>Sedang Mengunduh Data Posting</label>
                                    </center>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DetailUpdate;

