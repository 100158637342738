import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../../Constant'
import axios from 'axios'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class TunaikanZakat extends Component {
    constructor() {
        super()
        this.state = {
            user_id: null,
            categories: [],

            zakat: 0,
            category_id: 0,
            category: '',
            full_name: '',
            phone_number: '',
            email: '',

            go_back: false,
            go_paid: false,

            payment_method: 0,
            vendor: [],
            
            phone: '',
            card_number: '',
            exp_month: '',
            exp_year: '',
            cvn: '',
            alfamart_code: 'ALFAMARTCODEHERE!!!',
            
            go_payment: false,
            checkout_url: '',
            go_via_checkout: false
        }
    }

    componentDidMount() {
        this.getZakatCategories()
        this.setUserIfLogin()

        if(cookies.get('selected_method')){
            this.getPaymentMethod()
        }else{
            document.getElementById('logo_payment').hidden = true
            document.getElementById('label_phone').hidden = true
            document.getElementById('card_number').hidden = true
            document.getElementById('exp_month').hidden = true
            document.getElementById('exp_year').hidden = true
            document.getElementById('cvn').hidden = true
        }
    }

    getZakatCategories() {
        axios.get(Constant.GET_ZAKAT_CATEGORIES)
            .then(response => {
                // console.log(response.data)
                this.setState({ 
                    categories: response.data.data
                });
                this.checkCategoryFromProps()
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    setUserIfLogin(){
        if(cookies.get('user_id') != null){
            axios.get(Constant.GET_USER, {
                headers: {
                    'Authorization': cookies.get('accessToken')
                }
            }).then(response => {
                this.setState({
                    user_id: response.data.id,
                    full_name: response.data.name,
                    phone_number: response.data.no_telp,
                    email: response.data.email
                });

                document.getElementById('full_name').readOnly = true
                // document.getElementById('phone_number').readOnly = true
                document.getElementById('email').readOnly = true
                document.getElementById('btn_for_login').hidden = true

            }).catch(error => {
                console.log(error)
            })
        }
    }
    getPaymentMethod(){
        let selected_method = cookies.get('selected_method')
        if(selected_method != null || selected_method != ''){
            cookies.remove('linkBefore')

            if(cookies.get('customerBio')){
                this.setState({
                    zakat: cookies.get('customerBio').zakat,
                    user_id: cookies.get('customerBio').user_id,
                    full_name: cookies.get('customerBio').full_name,
                    phone_number: cookies.get('customerBio').phone_number,
                    email: cookies.get('customerBio').email,
                    category_id: cookies.get('customerBio').category_id,
                    category: cookies.get('customerBio').category,

                    payment_method: cookies.get('selected_method'),
                    vendor: cookies.get('vendor')
                })
            }

            console.log(cookies.get('vendor').payment_name)
            
            if(cookies.get('vendor').payment_name == "OVO" || cookies.get('vendor').payment_name == "LINKAJA"){
                document.getElementById('label_phone').hidden = false
                document.getElementById('phone').hidden = false
            }else{
                document.getElementById('label_phone').hidden = true
                document.getElementById('phone').hidden = true
            }

            if(cookies.get('vendor').payment_name == "Visa, Mastercard, JCB"){
                document.getElementById('card_number').hidden = false
                document.getElementById('exp_month').hidden = false
                document.getElementById('exp_year').hidden = false
                document.getElementById('cvn').hidden = false
            }else{
                document.getElementById('card_number').hidden = true
                document.getElementById('exp_month').hidden = true
                document.getElementById('exp_year').hidden = true
                document.getElementById('cvn').hidden = true
            }
        }
    }
    checkCategoryFromProps() {
        let props_category = this.props.location.state.category
        let props_zakat = this.props.location.state.total_zakat

        if (props_category) {
            this.state.categories.map((category) => {
                if (category.category == props_category)
                    this.setState({ category_id: category.id })
            })
        }

        if (props_zakat) this.setState({ zakat: props_zakat })
    }
    
    onAmountChanged(e) {
        let { name, value } = e.target
        this.setState({ [name]: parseInt(value.split('.').join('')) })
    }
    handleInputChange(e) {
        const { name, value } = e.target
        if (name == "category") {
            this.setState({
                [name]: e.target.options[e.target.selectedIndex].text,
                [name+"_id"]: value
            })
            // console.log(name, value, e.target.options[e.target.selectedIndex].text)
        } else {
            this.setState({ [name]: value})
        }
    }
    goToPayment(){
        const { 
            zakat, user_id, full_name, phone_number, email, category_id, category
        } = this.state

        let url = this.props.location.pathname
        let array = {
            'zakat': zakat,
            'user_id': user_id,
            'full_name': full_name,
            'phone_number': phone_number,
            'email': email,
            'category_id': category_id,
            'category': category,
        }

        cookies.set('linkBefore', url);
        cookies.set('customerBio', array);
        this.setState({ go_payment: true })
    }

    doZakat() {
        const {
            go_back, user_id, category_id, category,
            zakat, full_name, phone_number, email, vendor, phone
        } = this.state

        if(vendor.payment_type != "e_wallet"){
            if(!zakat || zakat < 10000){
                alert('Zakat Untuk Metode Pembayaran Ini Minimal Rp.10.000')
            } 
        }else{
            if(!zakat || zakat < 1){
                alert('Zakat Minimal Rp.1')
            } 
        }

        if(category_id == "" || category_id == null){
            alert('Harap Pilih Kategori Zakat')
        }
        // else if(!zakat || zakat < 1){
        //     alert('Zakat Minimal Rp.1')
        // }
        else if(full_name == "" || full_name == null){
            alert('Nama Lengkap Tidak Boleh Kosong')
        }else if(phone_number == "" || phone_number == null){
            alert('Nomor Telepon Tidak Boleh Kosong')
        }else if(email == "" || email == null){
            alert('Email Tidak Boleh Kosong')
        }else if(/(.+)@(.+){2,}\.(.+){2,}/.test(email)){
            let vendor_name = vendor.payment_name
            if(vendor_name == "OVO"){
                if(phone == null || phone == ""){
                    alert('Harap Isi Nomor Telepon E-Wallet Anda')
                }else{
                    document.getElementById("ovo_popup").style.display = "block"
                    this.sendTransaction()
                }
            }else{
                this.sendTransaction()
            }
        }else{
            alert('Email Tidak Valid')
        }
    }
    updateDeviceTypeTransaction(id) {
        let parameter = {
            id: id,
            transaksi_id: "Z"+id,
            device: "PWA"
        }
        axios.post(Constant.UPDATE_DEVICE_AFTER_TRANSACTION, parameter, {
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            .then(response => {
                // console.log(response.data)
                this.setState({ go_paid: true })
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    sendTransaction(){
        const { 
            user_id, category, zakat, vendor, payment_method, phone, 
            full_name, phone_number, email, category_id,
            card_number, exp_month, exp_year, cvn
        } = this.state

        let nomor_hp = null
        if(vendor.payment_name == 'OVO' || vendor.payment_name == 'DANA'){
            nomor_hp = phone
        }else{
            nomor_hp = phone_number
        }
        let metode_pembayaran = null
        if(vendor.payment_type == 'virtual_account'){
            metode_pembayaran = vendor.midtrans_code
        }else if(vendor.payment_name == "Alfamart"){
            metode_pembayaran = vendor.payment_name.toUpperCase()
        }else if(vendor.payment_name == "GOPAY"){
            metode_pembayaran = vendor.payment_name.toLowerCase()
        }else{
            metode_pembayaran = vendor.payment_name
        }

        let array = []
        if(vendor.payment_type == 'credit_card'){
            array = {
                'account_number': card_number,
                'exp_month': exp_month,
                'exp_year': exp_year,
                'cvn': cvn
            }
        }

        const parameter = {
            'jumlah_zakat': zakat,
            'nama_produk': category,
            'nama': full_name,
            'email': email,
            'no_telp': phone_number,
            'user_id': user_id,
            'vendor': vendor.vendor,
            'payment_type': vendor.payment_type,
            'payment_method': metode_pembayaran,
            'card_data': array,
            'phone': nomor_hp,
            'device': 'PWA',
            'category_id': category_id,
        }
        console.log(parameter, category)
        axios.post(Constant.XENDIT_SNAPTOKEN_ZAKAT, parameter)
            .then(response => {
                console.log(response)
                if(response.data.snap_token != null){
                    cookies.remove('linkBefore')
                    cookies.remove('customerBio')
                    cookies.remove('selected_method')
                    cookies.remove('vendor')
                    console.log(response)
                    window.snap.pay(response.data.snap_token, {
                        // Optional
                        onSuccess: function(result){
                            let pisah_url = window.location.href.split('/')
                            window.location = pisah_url[0]+'//'+pisah_url[2]+'/notif/notif-wait'
                        },
                        // Optional
                        onPending: function(result){
                            let pisah_url = window.location.href.split('/')
                            window.location = pisah_url[0]+'//'+pisah_url[2]+'/notif/notif-wait'
                        },
                        // Optional
                        onError: function(result){
                            console.log("gagal")
                        },
                        onClose: function(result){
                            console.log(response.data)
                            let id = ''
                            if(vendor.vendor == "midtrans"){
                                id = response.data.snap_token
                            }
                            const data_send = {
                                iDtoken: id,
                                status: 1
                            }
                            axios.post(Constant.DELETE_SNAPTOKEN, data_send)
                            .then(response => {
                                alert("Transaksi Dibatalkan")
                            })
                            .catch(error => {
                                console.log(error)
                            })
                        }
                    })
                }else{
                    console.log(response)
                    if(response.data.data_ew){
                        window.location.href = response.data.data_ew.checkout_url
                    }
                    if(response.data.data_ro){
                        this.setState({
                            alfamart_code: response.data.data_ro.payment_code
                        })
                        console.log(this.state.alfamart_code)
                        document.getElementById("alfamart_popup").style.display = "block"
                    }
                }
            })
    }

    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }

    render() {
        const {
            go_back, categories, category_id,
            zakat, full_name, phone_number, email, go_paid,
            go_payment, alfamart_code, phone, card_number, exp_month, exp_year, cvn, vendor
        } = this.state

        let foto = ''

        if(vendor){
            foto = Constant.GET_PICT_VENDOR+vendor.id
        }

        if (go_back)
            return <Redirect exact to="/zakat" />
        else if(go_paid)
            return <Redirect exact to="/notif/notif-wait" />
        else if(go_payment)
            return <Redirect exact to="/payment_method" />

        return(
        <div>
			<header id="header">
				<nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({ go_back: true })}>
                    </i>
                    Tunaikan Zakat
				</nav>
			</header>

            {/* OVO */}
            <div id="ovo_popup" className="modalfilter">
                <div className="filter-content">
                    <span className="close" onClick={() => document.getElementById("ovo_popup").style.display = "none"}>
                        &times;
                    </span>

                    <p className="head-filter">OVO</p>
                    <p className="all-category">
                        <img src={foto} style={{maxWidth: 150+'px'}}/>
                    </p>
                    <div className="scroller-category" style={{height: 67+'vh'}}>
                        <center>
                            <b>Instruksi Pembayaran</b>
                            <br/>
                            Nominal Yang Akan Didonasikan : 
                            <br/>
                            <h2 >Rp. {this.numberFormat((zakat)?zakat:0)}</h2>
                            <br/>
                            Periksa aplikasi OVO anda dan segera selesaikan pembayaran dalam 30 detik!
                        </center>
                        <b>Notes : </b>
                        <br/>
                        Apabila transaksi gagal, silahkan coba cara berikut : 
                        <br/>
                        1. Masukan kembali nomor telepon dan coba kembali pembayaran.
                        <br/>
                        2. Bersihkan cache aplikasi OVO anda.
                        <br/>
                        3. Apabila transaksi masih gagal, silahkan coba kembali dengan metode pembayaran yang lain. Anda TIDAK akan dikenakan biaya dua kali untuk mencoba kembali pembayaran
                        <br/>
                        <br/>
                        <a className="btn-dns" style={{padding: 10+"px", position: 'absolute', bottom: 66+'px', width: 85+'%', marginLeft: 10+'px'}} onClick={() => this.setState({go_paid: true})}><p>Saya Sudah Bayar</p></a>
                    </div>
                </div>
            </div>

            {/* ALFAMART */}
            <div id="alfamart_popup" className="modalfilter">
                <div className="filter-content">
                    <span className="close" onClick={() => document.getElementById("alfamart_popup").style.display = "none"}>
                        &times;
                    </span>

                    <p className="head-filter">ALFAMART</p>
                    <p className="all-category">
                        <img src={foto} style={{maxWidth: 150+'px'}}/>
                    </p>
                    <div className="scroller-category">
                        <center>
                            <b>Instruksi Pembayaran</b>
                            <br/>
                            Nominal Yang Akan Didonasikan : 
                            <br/>
                            <h2 style={{marginBottom: 0+'px'}}>Rp. {this.numberFormat((zakat)?zakat:0)}</h2>
                            <br/>
                            <h3 style={{marginBottom: 0+'px'}}>{alfamart_code}</h3>
                            <br/>
                            Harap simpan kode pembayaran di atas untuk melakukan pembayaran!
                        </center>
                        <a className="btn-dns" style={{padding: 10+"px", position: 'absolute', bottom: 125+'px', width: 85+'%', marginLeft: 10+'px'}} onClick={() => this.setState({go_paid: true})}><p>Selesaikan Pembayaran</p></a>
                    </div>
                </div>
            </div>

            <section id="donasi-box">
                <center><p className="title-donasi">Tunaikan Zakat</p></center>
                <br />
                
                <p className="label-form">Kategori Zakat</p>
                <form>
                    <select 
                        name="category" 
                        id="category" 
                        className="input-form" 
                        value={category_id} 
                        onChange={(e) => this.handleInputChange(e)}
                    >
                        <option value="0" key="-1">--- Pilih Kategori ---</option>
                    {categories.map((category, i) => {
                        return(
                            <option value={category.id} key={i}>
                                {category.category}
                            </option>
                        )
                    })}
                    </select>

                    <p className="label-form">Jumlah Zakat</p>
                    <input 
                        type="text" 
                        name="zakat" 
                        id="zakat" 
                        className="input-form" 
                        value={this.numberFormat((zakat)?zakat:0)} 
                        onChange={(e) => this.onAmountChanged(e)}
                    />

                    <br />
                    <table style={{marginBottom:0+'px'}} id="logo_payment">
                        <tbody>
                            <tr>
                                <td width="30%">
                                    <img src={foto} style={{maxWidth: 80+'px'}}/>
                                </td>
                                <td style={{verticalAlign: 'text-top'}}>
                                    {(vendor) ? vendor.payment_name : ''}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <a className="btn-dns" id="btn_choose_method" style={{padding: 10+"px"}} onClick={() => this.goToPayment()}><p>Pilih Metode Pembayaran</p></a>

                    <div id="btn_for_login">
                        <br/>
                        <center>
                            <a className="red-text" href="/login">
                                Silahkan Login atau isi data di bawah ini
                            </a>
                        </center>
                    </div>

                    <p className="label-form" id="label_phone">Nomor Telepon E-Wallet</p>
                    <input type="number" name="phone" id="phone" className="input-form" value={phone} onChange={(e) => this.handleInputChange(e)} />
                    
                    {/* credit_card */}
                    {/* <p className="label-form" id="label_phone"></p> */}
                    <input type="number" name="card_number" placeholder="Card Number" id="card_number" className="input-form" value={card_number} onChange={(e) => this.handleInputChange(e)} />
                    
                    {/* <p className="label-form" id="label_phone"></p> */}
                    <input type="number" name="exp_month" placeholder="Expired Month" id="exp_month" className="input-form" max="12" value={exp_month} onChange={(e) => this.handleInputChange(e)} />
                    
                    {/* <p className="label-form" id="label_phone"></p> */}
                    <input type="number" name="exp_year" placeholder="Expired Year" id="exp_year" className="input-form" max="2100" value={exp_year} onChange={(e) => this.handleInputChange(e)} />
                    
                    {/* <p className="label-form" id="label_phone"></p> */}
                    <input type="number" name="cvn" placeholder="CVN" id="cvn" className="input-form" value={cvn} onChange={(e) => this.handleInputChange(e)} />
                    {/* end credit_card */}
                    
                    <p className="label-form">Nama Lengkap</p>
                    <input 
                        type="text"
                        name="full_name"
                        id="full_name"
                        className="input-form"
                        value={full_name}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <p className="label-form">No Handphone / Whatsapp</p>
                    <input 
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        className="input-form"
                        value={phone_number}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <p className="label-form">Email</p>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        className="input-form"
                        value={email}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    <br />

                    <a 
                        className="btn-dns" 
                        style={{padding: "10px"}} 
                        onClick={() => {this.doZakat()}}
                    >
                        <p>Lanjutkan Pembayaran</p>
                    </a>
                </form>
               
            </section>
        </div>
        )
    }
}

export default TunaikanZakat