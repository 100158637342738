import React, { Component } from 'react'
import {
    Redirect,
    Link
} from "react-router-dom"
import Cookies from 'universal-cookie'
import Axios from 'axios';
import * as Constant from '../Constant'

import Header from '../Components/Header';
import Footer from '../Components/Footer';
import MitraPerusahaan from '../Components/MitraPerusahaan';

const cookies = new Cookies();
class Hubungi extends Component {
    constructor() {
        super()
        this.state = {
            go_home: false
        }
    }

    componentDidMount() {
        // this.ifLogin()
    }

    newTabClickedW() {
        let linkWhatsapp = window.open(Constant.LINK_WA, '_blank')
        linkWhatsapp.focus()
    }

    newTabClickedF() {
        let linkFacebook = window.open(Constant.LINK_FACEBOOK, '_blank')
        linkFacebook.focus()
    }

    handleCheck(e) {
        console.log(e)
    }

    render() {
        const {
            go_home
        } = this.state

        if (this.props.match.url === "/hub")
            window.location.href = "/hubungi"
        if (go_home)
            return <Redirect exact to='/' />

        return (
            <div className="page-content" id="page">
                <Header />
                <br></br>

                <div className="content-boxed">
                    <center>
                        <div style={{ width: 100 + 'px' }}>
                            <img src="/hub.png" style={{ marginTop: 20 + 'px', width: 50 + 'px' }} />
                        </div>
                    </center><br />
                    <center>
                        <h5 className="notif-transaksi">Hubungi Kami</h5>
                        <div className="kontak-alamat">
                            <p>Jl. Graha Bukit Raya III No.24, Cilame, Kec. Ngamprah, Kabupaten Bandung Barat, Jawa Barat 40552</p>
                        </div>
                    </center>

                    {/* <a className="kontak" onClick={() => this.newTabClickedI()}>Instagram</a>
                <a className="kontak" onClick={() => this.newTabClickedW()}>WhatsApp</a> */}

                    <table>
                        <thead>
                            <tr>
                                <td>
                                    <div className="float-right">
                                        <a style={{ marginRight: 10 + 'px' }} href="#" onClick={() => this.newTabClickedW()}>
                                            <img src="/wa2.png" style={{ maxWidth: '50px' }} />
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div className="float-left">
                                        <a style={{ marginLeft: 10 + 'px' }} href="#" onClick={() => this.newTabClickedF()}>
                                            <img src="/facebook.png" style={{ maxWidth: '50px' }} />
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </thead>
                    </table>
                    <br />
                </div>

                <MitraPerusahaan />
                <Footer />
            </div>
        )
    }
}

export default Hubungi