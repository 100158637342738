import React, { Component } from 'react'
import {
    Redirect,
    Link
} from 'react-router-dom'
import { MetodePembayaran, Input, Nominal } from '../Components/FormComponent';
import * as Constant from '../Constant'
import { askForPermissioToReceiveNotifications } from '../push-notification'
import axios from 'axios'
import Cookies from 'universal-cookie';
import Header from '../Components/Header';

const cookies = new Cookies();

class Login extends Component {
    constructor() {
        super()
        this.state = {
            username: '',
            password: '',
            fcmToken: cookies.get('fcmToken'),
            is_guest: false,

            go_home: false
        }
    }

    componentDidMount() {
        console.log("fcmToken", cookies.get('fcmToken'))
    }

    handleInputChange(e) {
        const { name, value } = e.target
        alert(e.target)
        this.setState({ [name]: value })
        // }
    }
    callbackInput = (name, value) => {
        this.setState({ [name]: value })
    }
    guestLogin() {
        cookies.set('is_guest', true);
        this.setState({ is_guest: true })
    }
    doLogin() {
        const {
            username, password, fcmToken, go_home
        } = this.state
        // console.log(username, password, fcmToken)

        const parameter = {
            "username": username,
            "password": password,
            "fcmToken": cookies.get('fcmToken'),
            "key_yayasan": Constant.KEY_YAYASAN
        }

        axios.post(Constant.LOGIN, parameter, {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if (response.data.status == 400) {
                alert(response.data.errors)
            } else if (response.data.access_token) {
                axios.get(Constant.GET_USER, {
                    headers: {
                        'Authorization': response.data.access_token
                    }
                }).then(response2 => {
                    // console.log(response2)
                    cookies.set('username', username);
                    cookies.set('user_id', response2.data.id);
                    cookies.set('accessToken', response.data.access_token);
                    cookies.set('is_guest', false);

                    console.log("BERHASIL LOGIN")

                    this.setState({ go_home: true })
                }).catch(error => {
                    console.log(error)
                })
            }
        }).catch(error => {
            console.log(error)
        })
    }

    render() {
        const {
            username, password, fcmToken, go_home, is_guest
        } = this.state
        let user_id = cookies.get('user_id');
        if (user_id != null) {
            return <Redirect exact to="/" />
        }
        if (is_guest == true) {
            return <Redirect exact to="/" />
        }
        if (go_home)
            return <Redirect exact to="/" />

        return (
            <div className="page-content">
                <Header />
                <div className="content-boxed">
                    <section id="donasi-box">
                        <center><label style={{ fontWeight: 'bold', fontSize: '22px', color: '#FFD52F' }}>Login</label></center>
                        <Input type="text" name="username" value={username} placeholder="Masukkan Username" callback={this.callbackInput} />
                        <Input type="password" name="password" value={password} placeholder="Masukkan Password" callback={this.callbackInput} />
                        <Input type="hidden" name="fcmToken" value={fcmToken} callback={this.callbackInput} />
                        <a className="donasi" onClick={() => this.doLogin()} style={{ cursor: 'pointer' }}>LOGIN</a>
                        <br/>
                        <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: "small" }}>
                            <Link to="/daftar">
                                Tidak Punya Akun? Daftar Disini !
                            </Link>
                            <Link to="/lupa_password">
                                Lupa Password ?
                            </Link>
                        </div>
                        <hr/>
                        <center>Atau</center>
                        <center>
                        <a className="donasi" onClick={() => this.guestLogin()} style={{ cursor: 'pointer' }}>Masuk Sebagai Guest</a>
                        </center>
                    </section>
                </div>
            </div>
        )
    }
}

export default Login