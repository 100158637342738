import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom'
import * as Constant from '../Constant';
import OwlCarousel from 'react-owl-carousel';


class ListHorizontal extends Component {
    constructor() {
        super();
    }

    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }

    dateDiff(startDate, endDate) {
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24))
    }


    render() {
        let title = 'Undefined';
        if (this.props.title) {
            title = this.props.title;
        }

        let data = null;
        if (this.props.data) {
            data = this.props.data;
        }

        let link = '/';
        if (this.props.link) {
            link = this.props.link
        }

        let numbers = 1;
        if (this.props.numbers) {
            numbers = this.props.numbers;
        }
        return (
                <div className="content-boxed">

                    <div className="content bottom-10">
                        <h5 className="float-left font-600">{title}</h5>
                        {/* <a href="#" className="float-right opacity-70">View All</a> */}
                        <Link to={link} className="category-program" style={{ float: 'right', fontWeight: 'bold' }}>Lihat Lainnya...</Link>
                        <div className="clear"></div>
                    </div>
                    <div className="container-fluid">
                        {
                            data !== null ?
                                <OwlCarousel items={numbers}
                                    className="owl-theme"
                                    loop
                                    dots={false}
                                    lazyLoad={true}
                                    margin={2}
                                >
                                    {data.map((val, i) => {
                                        let image_link = '';
                                        let percent = 0;
                                        let today = Date.now();
                                        let final_day = new Date(val.tanggal);
                                        let day_remaining = this.dateDiff(today, final_day);
                                        if (link === 'update') {
                                            image_link = Constant.GET_UPDATES_IMAGE + val.id_posting;
                                        }
                                        if (link === 'programs') {
                                            image_link = Constant.GET_PROGRAMS_IMAGE + val.id;
                                            percent = (val.total / val.dana_target) * 100;
                                            if (percent > 100) {
                                                percent = 100;
                                            }
                                            if (!val.tanggal) {
                                                final_day = null
                                                day_remaining = "∞"
                                            }
                                        }

                                        return (
                                            <>
                                                {link === 'update' ?
                                                    <Link to={'/update/' + val.seo} className="red-text padding-left-right" key={i}>
                                                        <div className="content">
                                                            <img className="update-slider-image" style={{ borderRadius: '10px' }} src={image_link} />
                                                            <div className="update-slider-desc">
                                                                <b><p className="title-program">{val.judul}</p></b>
                                                                <div className="category-program"><i style={{ fontWeight: 'bold' }}>{val.category}</i></div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    :
                                                    <Link className="red-text padding-left-right" to={'/detailprogram/' + val.seo} key={i}>
                                                        <div className="content" id="program-list-content">
                                                            <img className="program-home-image" src={image_link} style={{width: '50%', borderRadius: '5px'}} />
                                                            <div className="program-desc">
                                                                <b><p className="title-program" style={{ fontSize: '12px' }}>{String(val.judul).substring(0, 30)}...</p></b>
                                                                <div className="category-program">
                                                                    <i style={{ fontWeight: 'bold' }}>{val.category}</i>
                                                                </div>
                                                                <div className="w3-light-grey">
                                                                    <div className="w3-red" style={{ height: '5px', width: `${percent}%` }}></div>
                                                                </div>
                                                                <div className="dana" style={{ marginTop: '10px' }} >
                                                                    <div className="dana-terkumpul">
                                                                        Terkumpul <br /><b><p>Rp. {this.numberFormat(val.total)}</p></b>
                                                                    </div>
                                                                    <div className="sisa-hari">
                                                                        Sisa Hari <br />
                                                                        <b><p>{day_remaining}</p></b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>

                                                }
                                            </>
                                        )
                                    })}
                                </OwlCarousel>
                                :
                                <center>
                                    <label>Tidak ada data.</label>
                                </center>
                        }
                    </div>

                </div>
        )
    };
}

export default ListHorizontal;