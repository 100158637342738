import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../Constant'
import { askForPermissioToReceiveNotifications } from '../push-notification'
import axios from 'axios'
import Cookies from 'universal-cookie';
import FileBase64 from 'react-file-base64';
import Header from '../Components/Header';

const cookies = new Cookies();

class Profile extends Component {
    constructor() {
        super()
        this.state = {
            id: '',
            email: '',
            nama: '',
            alamat: '',
            no_telp: '',
            bio: '',
            foto: '',
            new_foto: '',
            fotobase64: '',
            accessToken: cookies.get('accessToken'),

            go_home: false
        }
    }

    componentDidMount() {
        this.getProfile()
    }

    handleInputChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value})
    }
    getFiles(files){
        this.setState({ fotobase64: files[0].base64 })
    }

    getProfile(){
        const {
            email, nama, alamat, no_telp, bio, accessToken
        } = this.state

        axios.get(Constant.GET_USER, {
            headers: {
                'Authorization': accessToken
            }
        }).then(response => {
            this.setState({
                id: response.data.id,
                email: response.data.email,
                nama: response.data.name,
                alamat: response.data.alamat,
                no_telp: response.data.no_telp,
                bio: response.data.bio,
            })
        }).catch(error => {
            console.log(error)
        })
    }
    getFoto(){
        const{id} = this.state
        axios.get(Constant.GET_PROFILE_PICTURE + id)
            .then(response => {
                this.setState({foto: response})
            })
    }
    
    doUpdate(){
        const {
            email, nama, alamat, no_telp, bio, accessToken
        } = this.state

        // alert(accessToken)

        const parameter = {
            'name': nama,
            'alamat': alamat,
            'no_telp': no_telp
        }

        axios.post(Constant.UPDATE_PROFILE, parameter, {
            headers: {
                'Authorization': accessToken,
            }
        }).then(response => {
            if(response.status == 400){
                alert(response.status.message)
            }else{
                this.updateProfilePic()
            }
        }).catch(error => {
            console.log(error)
        })
    }
    updateProfilePic(){
        const {
            new_foto, accessToken
        } = this.state

        const formData = new FormData();
        formData.append('file',new_foto)

        // console.log(new_foto)

        const parameter = {
            'photo': formData
        }
        console.log(parameter, Constant.UPDATE_PROFILE_PICTURE)

        axios.post(Constant.UPDATE_PROFILE_PICTURE, parameter, {
            headers: {
                'Authorization': accessToken,
                // 'content-type': 'multipart/form-data'
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(response => {
            console.log(response)
        }).catch(error => {
            console.log(error)
        })
    }
    klik_poto(){
        document.getElementById('file_foto').click()
    }
    onFileChange(e) {
        this.setState({new_foto: e.target.files[0]})
    }

    render() {
        const {
            id, email, nama, alamat, no_telp, foto, fotobase64, go_home
        } = this.state

        let poto = ''

        if(fotobase64 == ''){
            poto = Constant.GET_PROFILE_PICTURE + id;
        }else{
            poto = fotobase64
        }

        console.log(poto)
        
        if (go_home)
            return <Redirect exact to="/home" />

        return(
        <div>
            <header id="header">
                <Header />
				<nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({go_home:true})}
                        style={{
                            margin: "10px"
                        }}>
                    </i>
                    Profile
				</nav>
			</header>
            
			<section id="profile-box">
                <form>
                    <center>
                        <div style={{width:200+'px', height:200+'px'}}>
                            <img 
                                src={poto}
                                style={{ borderRadius:50+'%', width: "inherit" }}
                                onClick={ () => this.klik_poto() }
                            />
                        </div>

                        {/* <FileBase64
                            multiple={ true }
                            onDone={ this.getFiles.bind(this) }
                            value={foto}
                        /> */}

                        <input
                            type="file"
                            id="file_foto"
                            accept=".png, .jpg, .jpeg"
                            hidden
                            multiple
                            onChange={(e)=> this.onFileChange(e)}
                        />
                    </center>

                    <input 
                        type="text" 
                        name="email" 
                        id="email" 
                        className="input-form"
                        placeholder="email"
                        value={email}
                        disabled="disabled"
                        onChange={(e) => this.handleInputChange(e)}
                    />

                    <input 
                        type="text" 
                        name="nama" 
                        id="nama" 
                        className="input-form"
                        placeholder="nama"
                        value={nama}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <input 
                        type="text" 
                        name="alamat" 
                        id="alamat" 
                        className="input-form"
                        placeholder="alamat"
                        value={alamat}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <input 
                        type="number" 
                        name="no_telp" 
                        id="no_telp" 
                        className="input-form"
                        placeholder="no_telp"
                        value={no_telp}
                        onChange={(e) => this.handleInputChange(e)}
                    />

                    <br/><br/>
                    
                    <a 
                        className="btn-dns" 
                        style={{padding: "10px"}}
                        onClick={() => this.doUpdate()}
                    >
                        <p style={{color: "white"}}>Update Data Diri</p>
                    </a>
                </form>
                <br/><br/>
               
            </section>
        </div>
        )
    }
}

export default Profile